export const words = [
  ["a", "A cat is on the mat."],
  ["abandon", "He had to abandon the project."],
  ["abandon", "They had to abandon the old car."],
  ["ability", "He has the ability to solve problems."],
  ["ability", "She has the ability to sing well."],
  ["able", "She is able to help."],
  ["about", "We talked about the book."],
  ["about", "We talked about the weather."],
  ["about", "We talked about why bananas wear pajamas."],
  ["above", "The balloon floated above the giant ice cream cone."],
  ["above", "The kite flew above the trees."],
  ["above", "The plane flew above the clouds."],
  ["absent", "She was absent from school."],
  ["absorb", "The sponge will absorb the water."],
  ["abuse", "Abuse of any kind is wrong."],
  ["academy", "She attends a music academy."],
  ["accent", "He has a strong accent."],
  ["accept", "Please accept the gift."],
  ["accept", "She will accept the gift."],
  ["access", "They have access to the library."],
  ["accident", "She had a car accident."],
  ["accident", "She had an accident on the way home."],
  ["account", "He opened a bank account."],
  ["accuse", "Don't accuse someone without proof."],
  ["across", "The bridge goes across the river."],
  ["across", "We walked across the bridge."],
  ["act", "He likes to act in school plays."],
  ["act", "She likes to act in plays."],
  ["action", "The superhero movie was full of action."],
  ["active", "She is very active in sports."],
  ["activity", "The activity was fun."],
  ["actor", "He is a famous actor."],
  ["actually", "Actually, I agree with you."],
  ["actually", "Actually, I changed my mind."],
  ["adapt", "Animals adapt to their environment."],
  ["add", "Add some sugar to the tea."],
  ["add", "Please add more sugar."],
  ["additional", "We need additional information."],
  ["address", "She wrote the address on the envelope."],
  ["adjust", "He had to adjust his tie."],
  ["admire", "I admire her courage."],
  ["admit", "He had to admit he was wrong."],
  ["admit", "He had to admit his mistake."],
  ["adopt", "They decided to adopt a child."],
  ["adult", "She is an adult now."],
  ["adult", "She is now an adult."],
  ["advance", "They made an advance payment."],
  ["advantage", "There is an advantage to being early."],
  ["adventure", "They went on an adventure."],
  ["advertise", "They advertise their products online."],
  ["advice", "She gave me good advice."],
  ["advise", "He will advise you on the matter."],
  ["affect", "The weather can affect our plans."],
  ["affect", "The weather will affect our plans."],
  ["afford", "Can you afford a new car?"],
  ["afraid", "He is afraid of spiders."],
  ["afraid", "He is afraid of the dark."],
  ["after", "We went home after school."],
  ["after", "We went home after seeing a dancing robot."],
  ["after", "We went home after the game."],
  ["again", "He asked the joke again because it was that funny."],
  ["again", "He asked the question again."],
  ["again", "She asked the question again."],
  ["against", "She leaned against the wall."],
  ["against", "The team played against each other."],
  ["against", "They are against the new law."],
  ["age", "What is your age?"],
  ["age", "What is your age?"],
  ["agency", "He works at a travel agency."],
  ["agency", "The travel agency helped us book a trip."],
  ["agenda", "The agenda for the meeting is ready."],
  ["agent", "The agent showed us the house."],
  ["agent", "The secret agent went on a mission."],
  ["ago", "She moved here two years ago."],
  ["agree", "I agree with you."],
  ["agree", "I agree with your opinion."],
  ["agree", "They agree on the best solution."],
  ["ahead", "She looked ahead and saw the finish line."],
  ["ahead", "She looked ahead and saw the finish line."],
  ["aid", "They sent aid to the victims."],
  ["aim", "His aim is to win the game."],
  ["air", "The air is fresh and clean."],
  ["air", "The air is fresh today."],
  ["air", "The air is fresh."],
  ["aircraft", "The aircraft is ready for takeoff."],
  ["airport", "We arrived at the airport."],
  ["alarm", "The alarm woke us up."],
  ["album", "She has a photo album."],
  ["alert", "The alert warned us of the storm."],
  ["alike", "The twins look alike."],
  ["alive", "The plant is still alive."],
  ["all", "All the dinosaurs in my dream were wearing hats."],
  ["all", "All the kids are playing."],
  ["alley", "The cat ran down the alley."],
  ["allow", "They allow pets in the park."],
  ["allow", "They allow pets in the park."],
  ["ally", "They are our allies."],
  ["almost", "We almost caught the flying pizza."],
  ["almost", "We almost missed the bus."],
  ["almost", "We are almost finished."],
  ["almost", "We are almost there."],
  ["alone", "She felt alone in the crowd."],
  ["alone", "She likes to be alone sometimes."],
  ["alone", "She likes to be alone sometimes."],
  ["along", "We walked along the beach with our pet unicorn."],
  ["along", "We walked along the beach."],
  ["along", "We walked along the beach."],
  ["along", "We walked along the path."],
  ["alphabet", "The alphabet has 26 letters."],
  ["already", "She has already eaten breakfast."],
  ["already", "She has already finished her homework."],
  ["already", "She has already finished her homework."],
  ["also", "She likes apples and also bananas."],
  ["also", "She likes apples and also bananas."],
  ["also", "She likes cookies and also spaghetti for breakfast."],
  ["alter", "He had to alter his plans."],
  ["alternative", "They offered an alternative solution."],
  ["although", "Although it was raining, they went for a walk."],
  ["although", "Although it was raining, we went out."],
  ["altitude", "The altitude made it hard to breathe."],
  ["always", "He always brushes his teeth."],
  ["always", "He always laughs at funny cat videos."],
  ["always", "She always smiles."],
  ["am", "I am happy."],
  ["amaze", "The magician will amaze you."],
  ["ambition", "Her ambition is to become a doctor."],
  ["among", "She was among the first to arrive."],
  ["among", "There was one red apple among the green ones."],
  ["amount", "She has a large amount of homework."],
  ["amount", "The amount of sugar is too much."],
  ["amuse", "The clown will amuse the children."],
  ["an", "He saw an elephant juggling apples."],
  ["an", "She ate an apple."],
  ["analysis", "The analysis showed positive results."],
  ["analysis", "The scientist's analysis was thorough."],
  ["analyze", "He will analyze the data."],
  ["analyze", "They analyze the data carefully."],
  ["ancestor", "She traced her ancestor's history."],
  ["ancient", "The ruins are ancient."],
  ["and", "She has a pet dragon and a pet hamster."],
  ["and", "Tom and Jerry are friends."],
  ["and", "Tom and Jerry are friends."],
  ["anger", "His face was red with anger."],
  ["angle", "The angle is 90 degrees."],
  ["angry", "He was angry with his friend."],
  ["animal", "A dog is a friendly animal."],
  ["animal", "The cat is a cute animal."],
  ["animal", "The funny animal in the zoo wore sunglasses."],
  ["animal", "The zoo has many animals."],
  ["ankle", "She twisted her ankle."],
  ["announce", "They will announce the winner."],
  ["annual", "The annual event is in December."],
  ["another", "Can I have another cookie?"],
  ["another", "Can I have another cookie?"],
  ["another", "Can I have another slice of the rainbow cake?"],
  ["another", "She took another cookie."],
  ["answer", "He gave a silly answer to the riddle."],
  ["answer", "He gave the correct answer."],
  ["answer", "He gave the right answer."],
  ["answer", "She knew the answer."],
  ["anticipate", "They anticipate a good turnout."],
  ["anxiety", "She felt anxiety before the test."],
  ["any", "Do you have any magical beans?"],
  ["any", "Do you have any pencils?"],
  ["any", "Do you have any toys?"],
  ["anyone", "Anyone can join the club."],
  ["anything", "Do you know anything about it?"],
  ["anything", "She didn't say anything."],
  ["anyway", "We can go anyway."],
  ["apart", "They live apart but stay in touch."],
  ["apartment", "She moved into a new apartment."],
  ["apologize", "I apologize for being late."],
  ["appeal", "The decision is under appeal."],
  ["appear", "She will appear on TV."],
  ["appear", "The sun will appear soon."],
  ["apple", "She ate an apple for lunch."],
  ["apple", "She ate an apple."],
  ["application", "Submit your application online."],
  ["apply", "He will apply for the job."],
  ["apply", "You should apply for the job."],
  ["appoint", "They will appoint a new manager."],
  ["appreciate", "I appreciate your help."],
  ["approach", "He took a different approach."],
  ["approach", "The cat began to approach the bird slowly."],
  ["approve", "They approve the plan."],
  ["approximate", "The approximate cost is $10."],
  ["are", "They are my friends from outer space."],
  ["are", "They are my friends."],
  ["area", "The park is a great area for a picnic."],
  ["area", "This area is quiet."],
  ["argue", "They always argue about the dishes."],
  ["argue", "They argue about everything."],
  ["arise", "Problems may arise."],
  ["arm", "Her arm is in a cast."],
  ["arm", "She broke her arm."],
  ["army", "He joined the army."],
  ["around", "The earth revolves around the sun."],
  ["around", "We danced around the glowing tree."],
  ["around", "We walked around the park."],
  ["arrange", "Let's arrange the meeting for next week."],
  ["arrange", "She will arrange the meeting."],
  ["arrest", "The police will arrest the suspect."],
  ["arrive", "They will arrive soon."],
  ["arrive", "They will arrive soon."],
  ["arrive", "We will arrive at noon."],
  ["arrow", "He shot the arrow."],
  ["art", "She is good at making art."],
  ["art", "She loves modern art."],
  ["art", "She loves to draw and paint."],
  ["article", "He read an interesting article."],
  ["article", "She read an article."],
  ["artist", "He is a talented artist."],
  ["artist", "She is a talented artist."],
  ["as", "He is as tall as his brother."],
  ["as", "He was as excited as a puppy with a new toy."],
  ["ask", "Can I ask you a question?"],
  ["ask", "Can I ask you something?"],
  ["ask", "Can I ask you why you are wearing clown shoes?"],
  ["asleep", "The baby is asleep."],
  ["asleep", "The baby is asleep."],
  ["aspect", "This is one aspect of the problem."],
  ["assault", "The news reported an assault."],
  ["assemble", "They will assemble the furniture."],
  ["assert", "He will assert his rights."],
  ["assess", "They will assess the damage."],
  ["assign", "The teacher will assign homework."],
  ["assist", "Can you assist me?"],
  ["associate", "He is an associate at the firm."],
  ["assume", "I assume he will come."],
  ["assume", "I assume you will come."],
  ["assure", "I assure you it is safe."],
  ["asteroid", "An asteroid is in space."],
  ["at", "Meet me at the bouncy castle."],
  ["at", "Meet me at the park."],
  ["at", "We will meet at the park."],
  ["athlete", "She is a professional athlete."],
  ["atmosphere", "The atmosphere was tense."],
  ["attach", "Attach the file to the email."],
  ["attack", "The cat tried to attack the bird."],
  ["attack", "The knight prepared to attack the dragon."],
  ["attempt", "He will attempt to climb the mountain."],
  ["attempt", "They made an attempt."],
  ["attend", "She will attend the meeting."],
  ["attention", "Pay attention to the sign."],
  ["attention", "She paid attention in class."],
  ["attitude", "Her attitude is positive."],
  ["attorney", "The attorney prepared his case."],
  ["attract", "The flowers attract bees."],
  ["auction", "They held an auction."],
  ["audience", "The audience clapped at the end of the show."],
  ["audience", "The audience clapped."],
  ["aunt", "My aunt is visiting."],
  ["author", "She is the author of the book."],
  ["author", "She is the author of the book."],
  ["autumn", "Autumn leaves are beautiful."],
  ["autumn", "Autumn leaves are beautiful."],
  ["available", "The book is available at the library."],
  ["available", "The tickets are available now."],
  ["average", "The average score is 80."],
  ["avoid", "Please avoid the area."],
  ["avoid", "She tries to avoid fast food."],
  ["award", "She won an award."],
  ["aware", "Be aware of the danger."],
  ["away", "He lives far away."],
  ["away", "The bird flew away."],
  ["awesome", "The fireworks were awesome."],
  ["awful", "The food was awful."],
  ["baby", "The baby is crying."],
  ["baby", "The baby is sleeping."],
  ["baby", "The baby is sleeping."],
  ["bachelor", "He is a bachelor."],
  ["back", "He came back home."],
  ["back", "She came back home."],
  ["back", "She came back home."],
  ["back", "The ninja turtle came back to save the day."],
  ["bacteria", "Bacteria can cause illness."],
  ["bad", "She feels bad about the mistake."],
  ["bad", "That was a bad idea."],
  ["badge", "He wore a badge."],
  ["bag", "He carried his lunch bag."],
  ["bag", "She carried a heavy bag."],
  ["bag", "She carried a heavy bag."],
  ["balance", "She lost her balance."],
  ["ball", "He kicked the ball."],
  ["ball", "He threw the ball."],
  ["ball", "The ball bounced high."],
  ["ban", "They banned the book."],
  ["banana", "He peeled a banana."],
  ["band", "They listened to a band."],
  ["band", "They listened to a band."],
  ["bank", "He deposited money in the bank."],
  ["bank", "She has an account at the bank."],
  ["bank", "The bank is closed today."],
  ["bar", "He ate a chocolate bar."],
  ["bar", "She had a chocolate bar."],
  ["bar", "They sat at the bar."],
  ["bare", "His feet were bare."],
  ["bargain", "She found a bargain."],
  ["barrel", "The barrel is full of apples."],
  ["base", "The lamp is on the base."],
  ["base", "The lamp is on the base."],
  ["baseball", "He plays baseball."],
  ["baseball", "They played baseball."],
  ["basic", "He has a basic understanding."],
  ["basin", "She washed her hands in the basin."],
  ["basis", "The basis of the theory."],
  ["basket", "She put the apples in the basket."],
  ["basket", "The basket is full of apples."],
  ["basketball", "He plays basketball."],
  ["bat", "He hit the ball with a bat."],
  ["bath", "He took a warm bath."],
  ["bath", "She took a warm bath."],
  ["bathroom", "The bathroom is clean."],
  ["battery", "The battery is low."],
  ["battle", "The battle was fierce."],
  ["be", "I want to be a doctor."],
  ["be", "I want to be a teacher."],
  ["be", "She wants to be a doctor."],
  ["beach", "They went to the beach."],
  ["beam", "The beam of light was bright."],
  ["bean", "She ate a green bean."],
  ["bean", "She ate a green bean."],
  ["bear", "A bear is in the forest."],
  ["bear", "A bear is in the forest."],
  ["beat", "He can beat the drum."],
  ["beat", "He can beat the drum."],
  ["beautiful", "The flowers are beautiful."],
  ["beautiful", "The flowers are beautiful."],
  ["beautiful", "The flowers are beautiful."],
  ["became", "He became a teacher."],
  ["because", "She is crying because she is sad."],
  ["because", "She is happy because it is sunny."],
  ["because", "She is happy because she found a golden ticket."],
  ["because", "She is sad because it is raining."],
  ["become", "She wants to become a doctor."],
  ["become", "She will become a teacher."],
  ["bed", "He lay down on the bed."],
  ["bed", "She went to bed early."],
  ["bed", "The cat is sleeping on the bed."],
  ["bedroom", "Her bedroom is tidy."],
  ["bee", "A bee is buzzing."],
  ["beef", "He likes beef."],
  ["been", "She has been to the zoo."],
  ["been", "We have been to the moon in our dreams."],
  ["before", "Wash your hands before eating."],
  ["before", "Wash your hands before eating."],
  ["before", "Wash your hands before eating."],
  ["before", "Wash your hands before petting the unicorn."],
  ["beg", "He will beg for help."],
  ["began", "The show began on time."],
  ["begin", "Let's begin the game."],
  ["begin", "Let's begin the show."],
  ["begin", "Let's begin the show."],
  ["behavior", "His behavior is good."],
  ["behavior", "His behavior was not acceptable."],
  ["behind", "She stood behind him."],
  ["behind", "The cat is hiding behind the curtain."],
  ["behind", "The cat is hiding behind the curtain."],
  ["being", "Every being needs water."],
  ["believe", "Do you believe in magic?"],
  ["believe", "I believe in you."],
  ["believe", "I believe in you."],
  ["bell", "The bell rang."],
  ["bell", "The school bell rang."],
  ["belong", "These books belong to her."],
  ["below", "The book is below the desk."],
  ["belt", "He wore a belt."],
  ["bench", "She sat on the bench."],
  ["bend", "He had to bend down."],
  ["benefit", "Exercise has many benefits."],
  ["benefit", "The benefit of exercise."],
  ["berry", "She ate a berry."],
  ["beside", "She sat beside me."],
  ["best", "He is the best at telling funny stories."],
  ["best", "She is my best friend."],
  ["best", "She is the best student in the class."],
  ["best", "She is the best student."],
  ["bet", "He placed a bet."],
  ["better", "She feels better now."],
  ["better", "This book is better than that one."],
  ["better", "This joke is even better than the last one."],
  ["better", "This one is better."],
  ["between", "She sat between her friends."],
  ["between", "The ball rolled between the chairs."],
  ["between", "The cat is between the boxes."],
  ["beyond", "The mountains are beyond the lake."],
  ["beyond", "The mountains are beyond the valley."],
  ["bicycle", "He rides a bicycle."],
  ["big", "The big hamburger had eyes and a smile."],
  ["big", "The dog is very big."],
  ["big", "The dog is very big."],
  ["big", "The tree is big."],
  ["bike", "She rides her bike."],
  ["bill", "The bill is due."],
  ["bill", "The restaurant bill was high."],
  ["billion", "There are a billion stars."],
  ["billion", "There are billions of stars."],
  ["bin", "Put the trash in the bin."],
  ["biology", "She studies biology."],
  ["bird", "A bird is singing."],
  ["bird", "A bird is singing."],
  ["bird", "A bird wearing a tiny hat sang a song."],
  ["birth", "The birth of a baby."],
  ["birthday", "For her birthday, she got a talking teddy bear."],
  ["birthday", "Happy birthday to you."],
  ["birthday", "Today is my birthday."],
  ["biscuit", "She ate a biscuit."],
  ["bit", "He took a bit of cake."],
  ["bit", "He took a bit of cheese."],
  ["bit", "He took a bit of the sandwich."],
  ["bite", "She took a bite of the apple."],
  ["bitter", "The medicine is bitter."],
  ["black", "The black cat played the piano."],
  ["black", "The cat is black."],
  ["black", "The cat is black."],
  ["black", "The night is black."],
  ["blade", "The blade is sharp."],
  ["blame", "Don't blame him."],
  ["blank", "Fill in the blank."],
  ["blanket", "The blanket is warm."],
  ["blanket", "The blanket is warm."],
  ["blast", "The blast was loud."],
  ["bleed", "Her finger began to bleed."],
  ["bless", "Bless you when you sneeze."],
  ["blew", "He blew out the candle."],
  ["blind", "He is blind in one eye."],
  ["block", "The road is blocked."],
  ["blood", "She cut her finger and there was blood."],
  ["blood", "The nurse took a blood sample."],
  ["blow", "He will blow out the candles."],
  ["blue", "The blue dog chased its tail in circles."],
  ["blue", "The sky is blue."],
  ["blue", "The sky is blue."],
  ["blue", "The sky is blue."],
  ["board", "He wrote on the board."],
  ["board", "He wrote on the board."],
  ["boat", "They sailed on a boat."],
  ["boat", "They sailed on a boat."],
  ["body", "Her body was tired."],
  ["body", "His body is strong."],
  ["body", "She takes good care of her body."],
  ["boil", "The water will boil."],
  ["bomb", "The bomb exploded."],
  ["bone", "He broke a bone."],
  ["book", "She read a book."],
  ["book", "She read a book."],
  ["boost", "They need to boost sales."],
  ["boot", "She wore a boot."],
  ["border", "They crossed the border."],
  ["born", "She was born in June."],
  ["borrow", "Can I borrow your pen?"],
  ["boss", "Her boss is kind."],
  ["both", "Both apples are red."],
  ["both", "Both apples are ripe."],
  ["both", "Both options are good."],
  ["both", "Both the wizard and the knight had tea with the dragon."],
  ["bother", "Don't bother him."],
  ["bottle", "He drank from the bottle."],
  ["bottom", "The ball is at the bottom of the hill."],
  ["bottom", "The ball is at the bottom."],
  ["bought", "She bought a new dress."],
  ["boundary", "The boundary is marked."],
  ["bowl", "She ate from the bowl."],
  ["bowl", "She filled the bowl with soup."],
  ["box", "He opened the box."],
  ["box", "She put the toys in the box."],
  ["box", "The box is heavy."],
  ["boy", "The boy is playing outside."],
  ["boy", "The boy is playing."],
  ["boy", "The boy is playing."],
  ["brain", "The brain is complex."],
  ["brake", "Use the brake to stop."],
  ["branch", "A bird sat on the branch."],
  ["branch", "A bird sat on the branch."],
  ["brand", "She likes that brand."],
  ["brave", "He is a brave hero."],
  ["bread", "She baked fresh bread."],
  ["bread", "She made fresh bread."],
  ["break", "He needed a break from work."],
  ["break", "He took a break."],
  ["break", "He took a short break."],
  ["breakfast", "We had eggs for breakfast."],
  ["breakfast", "We had pancakes for breakfast."],
  ["breath", "Take a deep breath."],
  ["breathe", "Breathe deeply."],
  ["brick", "The house is made of brick."],
  ["bridge", "They crossed the bridge."],
  ["brief", "She gave a brief summary."],
  ["bright", "The sun is bright."],
  ["bring", "Bring your homework to class."],
  ["bring", "Please bring your book."],
  ["bring", "Please bring your book."],
  ["broad", "The street is broad."],
  ["broke", "She broke the vase."],
  ["broken", "The toy is broken."],
  ["brother", "Her brother is older."],
  ["brother", "Her brother is younger than her."],
  ["brother", "My brother is tall."],
  ["brought", "She brought a gift."],
  ["brought", "She brought cookies to the party."],
  ["brown", "The bear is brown."],
  ["brown", "The dog is brown."],
  ["brush", "She used a brush."],
  ["bubble", "The bubble floated away."],
  ["buddy", "He is my buddy."],
  ["budget", "The budget is limited."],
  ["budget", "They need to stick to their budget."],
  ["build", "They want to build a new house."],
  ["build", "They will build a house."],
  ["build", "They will build a house."],
  ["building", "The building is tall."],
  ["building", "The building is very tall."],
  ["bulb", "The light bulb is out."],
  ["bulk", "She bought in bulk."],
  ["bullet", "The bullet missed."],
  ["bump", "She felt a bump."],
  ["bunch", "He gave her a bunch of flowers."],
  ["bundle", "They carried a bundle of sticks."],
  ["burn", "Don't burn yourself."],
  ["burst", "The balloon burst."],
  ["bury", "They will bury the treasure."],
  ["bus", "They rode the bus."],
  ["bus", "We took the bus to school."],
  ["bush", "A rabbit hid in the bush."],
  ["business", "She owns a business."],
  ["business", "She owns a small business."],
  ["busy", "She is always busy."],
  ["but", "He is tall but she is short."],
  ["but", "I like apples but not bananas."],
  ["but", "I like ice cream but not chocolate."],
  ["but", "I like ice cream but not with broccoli."],
  ["butter", "She spread butter on the toast."],
  ["butter", "Spread butter on the bread."],
  ["butterfly", "A butterfly is flying."],
  ["button", "Press the button."],
  ["buy", "I want to buy a toy."],
  ["buy", "She wants to buy a new phone."],
  ["buy", "She wants to buy a toy."],
  ["buzz", "I heard a buzz."],
  ["by", "He rode by on his invisible bike."],
  ["by", "She sat by the window."],
  ["by", "She stood by the door."],
  ["cabbage", "She cooked cabbage."],
  ["cabin", "They stayed in a cabin."],
  ["cable", "The cable is long."],
  ["cactus", "A cactus grows in the desert."],
  ["cage", "The bird is in a cage."],
  ["cake", "She baked a cake."],
  ["cake", "She baked a cake."],
  ["calculate", "He can calculate the total."],
  ["call", "I will call you later."],
  ["call", "I will call you later."],
  ["call", "I will call you with my banana phone."],
  ["call", "She will call you later."],
  ["calm", "Stay calm."],
  ["came", "She came to my house."],
  ["came", "She came to my party."],
  ["came", "She came to the party dressed as a superhero."],
  ["camera", "He took a photo with his camera."],
  ["camera", "She took a photo with her camera."],
  ["camp", "They set up camp by the lake."],
  ["camp", "They set up camp near the lake."],
  ["campaign", "He ran a successful campaign."],
  ["campaign", "They started a campaign."],
  ["campus", "The campus is beautiful."],
  ["can", "Can you help me find my invisible friend?"],
  ["can", "Can you help me?"],
  ["can", "Can you help me?"],
  ["can", "Can you help me?"],
  ["canal", "They sailed down the canal."],
  ["cancel", "They had to cancel the event."],
  ["cancer", "He is raising money for cancer research."],
  ["candidate", "She is a candidate for the job."],
  ["candle", "She lit a candle."],
  ["candy", "She likes candy."],
  ["cane", "He walks with a cane."],
  ["cannot", "I cannot reach the top shelf."],
  ["canvas", "She painted on a canvas."],
  ["cap", "He wore a cap."],
  ["capable", "She is capable of doing it."],
  ["capacity", "The room's capacity is 100."],
  ["capital", "Paris is the capital of France."],
  ["capital", "Paris is the capital of France."],
  ["captain", "The captain sailed the ship."],
  ["capture", "They want to capture the moment."],
  ["car", "He drives a red car."],
  ["car", "She drives a red car."],
  ["car", "The car is red."],
  ["car", "The car zoomed past with a dinosaur driving it."],
  ["carbon", "Carbon is an element."],
  ["card", "He gave her a card."],
  ["card", "He gave her a card."],
  ["card", "She gave him a birthday card."],
  ["care", "She takes care of her plants."],
  ["care", "She takes care of her plants."],
  ["care", "She takes care of the plants."],
  ["career", "She has a successful career."],
  ["careful", "Be careful with the glass."],
  ["careful", "Be careful with the glass."],
  ["carefully", "She carefully placed the vase."],
  ["careless", "He made a careless mistake."],
  ["cargo", "The ship carried cargo."],
  ["carpet", "The carpet is soft."],
  ["carrot", "She ate a carrot."],
  ["carrot", "She ate a carrot."],
  ["carry", "Can you carry this for me?"],
  ["carry", "He can carry the box."],
  ["carry", "He can carry the box."],
  ["cart", "They pushed the cart."],
  ["cartoon", "He watches cartoons."],
  ["case", "She presented her case to the judge."],
  ["case", "She put the glasses in the case."],
  ["cash", "She paid in cash."],
  ["cast", "They removed the cast."],
  ["castle", "The castle is old."],
  ["cat", "The cat danced to the funky music."],
  ["cat", "The cat is sleeping."],
  ["cat", "The cat is sleeping."],
  ["catch", "Can you catch the ball?"],
  ["catch", "Can you catch the ball?"],
  ["catch", "He can catch the ball."],
  ["category", "It falls into that category."],
  ["cattle", "The cattle are grazing."],
  ["cattle", "The cattle are grazing."],
  ["caught", "He caught the ball."],
  ["caught", "He caught the ball."],
  ["cause", "The cause of the fire is unknown."],
  ["cause", "The cause of the fire is unknown."],
  ["cause", "The cause of the fire is unknown."],
  ["caution", "Proceed with caution."],
  ["cave", "They explored the cave."],
  ["ceiling", "The ceiling is high."],
  ["celebrate", "They celebrate her birthday."],
  ["cell", "A cell is a basic unit of life."],
  ["cell", "The prisoner was put in a cell."],
  ["cent", "The candy costs one cent."],
  ["cent", "The candy costs one cent."],
  ["center", "He stood in the center of the circle."],
  ["center", "Stand in the center."],
  ["center", "Stand in the center."],
  ["central", "The library is in a central location."],
  ["century", "That painting is from the 18th century."],
  ["century", "The building was built a century ago."],
  ["ceremony", "The ceremony was beautiful."],
  ["certain", "I am certain about it."],
  ["certain", "She is certain about the answer."],
  ["certain", "She is certain about the answer."],
  ["certainly", "This is certainly a mistake."],
  ["chain", "The chain is strong."],
  ["chain", "The chain is strong."],
  ["chair", "Please sit on the chair."],
  ["chair", "Please sit on the chair."],
  ["chair", "She sat on the chair."],
  ["chalk", "He wrote with chalk."],
  ["challenge", "He accepted the challenge."],
  ["chance", "Give her a chance."],
  ["chance", "Give me a chance."],
  ["chance", "There is a good chance it will rain."],
  ["change", "She needs to change her clothes."],
  ["change", "She needs to change her dress."],
  ["change", "She wants to change her hairstyle."],
  ["channel", "He changed the TV channel."],
  ["chaos", "The scene was chaos."],
  ["chapter", "She read a chapter of the book."],
  ["character", "The character is funny."],
  ["character", "The character is funny."],
  ["character", "The main character is very funny."],
  ["charge", "The phone needs to charge."],
  ["charge", "They charge a fee."],
  ["charge", "They charge a fee."],
  ["charm", "She has a charm bracelet."],
  ["chase", "The dog chased the cat."],
  ["chase", "The dog chased the cat."],
  ["chat", "They had a chat."],
  ["cheap", "The pen is cheap."],
  ["check", "Check your answer."],
  ["check", "Check your work before submitting."],
  ["check", "Check your work."],
  ["cheek", "She kissed his cheek."],
  ["cheer", "They cheer for the team."],
  ["cheese", "She likes cheese."],
  ["chef", "The chef cooked the meal."],
  ["chemical", "A chemical reaction occurred."],
  ["cherry", "She ate a cherry."],
  ["chess", "He plays chess."],
  ["chest", "Her chest feels tight."],
  ["chicken", "They had chicken for dinner."],
  ["chicken", "We had chicken for dinner."],
  ["chief", "He is the chief of the tribe."],
  ["child", "A child is playing."],
  ["child", "A child is playing."],
  ["child", "The child is playing outside."],
  ["children", "The children are laughing."],
  ["children", "The children are laughing."],
  ["chill", "There is a chill in the air."],
  ["chip", "She ate a chip."],
  ["chocolate", "She loves chocolate."],
  ["choice", "You have a choice of two colors."],
  ["choice", "You have a choice."],
  ["choose", "Choose a card."],
  ["choose", "Choose a color."],
  ["choose", "She will choose the blue dress."],
  ["chorus", "The chorus sang beautifully."],
  ["Christmas", "Christmas is a joyful holiday."],
  ["Christmas", "Christmas is a joyful time."],
  ["chuckle", "He gave a chuckle."],
  ["church", "They go to church on Sundays."],
  ["church", "They went to church."],
  ["cigarette", "He does not smoke cigarettes."],
  ["circle", "Draw a circle."],
  ["circle", "Draw a circle."],
  ["citizen", "She is a citizen of the country."],
  ["citizen", "She is a citizen of the country."],
  ["city", "New York is a big city."],
  ["city", "The city is noisy."],
  ["city", "We live in the city where the pigeons wear suits."],
  ["city", "We live in the city."],
  ["civil", "He showed civil behavior."],
  ["civil", "They had a civil discussion."],
  ["claim", "He made a claim for damages."],
  ["claim", "They made a claim."],
  ["class", "Our class is learning about animals."],
  ["class", "The class is learning about history."],
  ["class", "The class is studying math."],
  ["classroom", "The classroom is large."],
  ["classroom", "The classroom is large."],
  ["clay", "The pot is made of clay."],
  ["clean", "She likes to clean."],
  ["clean", "She likes to clean."],
  ["clear", "The sky is clear."],
  ["clear", "The sky is clear."],
  ["clear", "The water is very clear."],
  ["clearly", "She clearly explained the rules."],
  ["clerk", "The clerk was helpful."],
  ["click", "Click the button."],
  ["client", "The client was satisfied."],
  ["cliff", "They stood on the cliff."],
  ["climate", "The climate is warm."],
  ["climb", "They climb the hill."],
  ["climb", "They climb the mountain."],
  ["clinic", "She went to the clinic."],
  ["clock", "The clock shows the time."],
  ["clock", "The clock shows the time."],
  ["close", "Close the door."],
  ["close", "Close the door."],
  ["close", "Please close the door."],
  ["cloth", "The cloth is soft."],
  ["clothes", "She folded the clothes."],
  ["clothes", "She folded the clothes."],
  ["cloud", "The cloud is fluffy."],
  ["cloud", "The cloud is fluffy."],
  ["club", "He joined a club."],
  ["clue", "She found a clue."],
  ["coach", "The coach gave a pep talk."],
  ["coach", "The coach gave advice."],
  ["coal", "The train runs on coal."],
  ["coast", "They walked along the coast."],
  ["coat", "She wore a coat."],
  ["code", "Enter the code."],
  ["coffee", "She likes coffee."],
  ["coin", "He found a coin."],
  ["cold", "It is cold outside."],
  ["cold", "It is cold outside."],
  ["cold", "The water is very cold."],
  ["collar", "The shirt has a collar."],
  ["collect", "She likes to collect stamps."],
  ["collection", "She has a collection of stamps."],
  ["college", "She went to college."],
  ["college", "She will go to college."],
  ["colony", "Ants live in a colony."],
  ["color", "Her favorite color is blue."],
  ["color", "Her favorite color is blue."],
  ["color", "Her favorite color is blue."],
  ["column", "The article is in the first column."],
  ["combine", "Combine the ingredients."],
  ["come", "Come here and see this."],
  ["come", "Come here, please."],
  ["come", "Come here, quick! The cupcakes are dancing!"],
  ["comedy", "The movie is a comedy."],
  ["comfort", "The bed is comfortable."],
  ["command", "He gave a command."],
  ["comment", "She left a comment."],
  ["commerce", "They study commerce."],
  ["commercial", "They watched a commercial on TV."],
  ["commit", "He is ready to commit."],
  ["common", "It is a common mistake."],
  ["common", "It is a common mistake."],
  ["common", "It is a common mistake."],
  ["community", "She is involved in the local community."],
  ["community", "They live in a small community."],
  ["company", "He works for a big company."],
  ["company", "He works for a big company."],
  ["compare", "Let's compare our answers."],
  ["compare", "Let's compare the two pictures."],
  ["compare", "Let's compare the two pictures."],
  ["compass", "Use the compass to find north."],
  ["complain", "Do not complain about the food."],
  ["complete", "Complete the puzzle."],
  ["complete", "Complete the puzzle."],
  ["complex", "The math problem is complex."],
  ["complicate", "Do not complicate things."],
  ["compose", "She likes to compose music."],
  ["computer", "He has a new computer."],
  ["computer", "She uses a computer for work."],
  ["concept", "The concept is easy."],
  ["concern", "She has a concern."],
  ["concern", "There is a concern about safety."],
  ["concert", "They went to a concert."],
  ["condition", "The car is in good condition."],
  ["conduct", "He will conduct the interview."],
  ["conference", "They attended a conference."],
  ["confirm", "Please confirm your reservation."],
  ["conflict", "There is a conflict."],
  ["confuse", "Do not confuse the two."],
  ["Congress", "The bill was passed by Congress."],
  ["connect", "Connect the dots."],
  ["consider", "Please consider my offer."],
  ["consider", "Please consider my offer."],
  ["consider", "Please consider my request."],
  ["consist", "The team consists of five players."],
  ["constant", "There was constant noise."],
  ["consult", "She will consult a doctor."],
  ["consumer", "She is a smart consumer."],
  ["contain", "The box contains toys."],
  ["contain", "This box contains toys."],
  ["content", "The box's content is valuable."],
  ["contest", "She won the contest."],
  ["context", "Understand the context."],
  ["continue", "Please continue reading."],
  ["continue", "Please continue with your story."],
  ["contract", "They signed a contract."],
  ["contrast", "Notice the contrast."],
  ["control", "He lost control of the car."],
  ["control", "She has control of the car."],
  ["convenient", "The store is convenient."],
  ["convert", "Convert the temperature."],
  ["convince", "Try to convince her."],
  ["cook", "He likes to cook."],
  ["cook", "She likes to cook."],
  ["cookie", "She baked cookies."],
  ["cool", "The air is cool."],
  ["cool", "The weather is cool."],
  ["cooperate", "They cooperate well."],
  ["cope", "He can cope with stress."],
  ["copy", "Please make a copy."],
  ["coral", "The coral reef is beautiful."],
  ["corn", "They grow corn on the farm."],
  ["corner", "Stand in the corner."],
  ["corner", "Stand in the corner."],
  ["correct", "Your answer is correct."],
  ["correct", "Your answer is correct."],
  ["cost", "The cost is too high."],
  ["cost", "The cost of the book is $20."],
  ["cost", "The cost of the book is ten dollars."],
  ["cotton", "The shirt is made of cotton."],
  ["cotton", "The shirt is made of cotton."],
  ["couch", "She sat on the couch."],
  ["could", "Could you pass the rainbow-colored butter?"],
  ["could", "Could you pass the salt?"],
  ["could", "Could you pass the salt?"],
  ["could", "Could you pass the salt?"],
  ["council", "The council met today."],
  ["count", "She can count to ten."],
  ["count", "She can count to ten."],
  ["country", "Canada is a big country."],
  ["country", "They live in a big country."],
  ["country", "We live in a beautiful country."],
  ["couple", "The couple got married."],
  ["couple", "The couple is happy."],
  ["course", "She is taking a math course."],
  ["course", "The course is challenging."],
  ["course", "The course is hard."],
  ["court", "He went to court for the trial."],
  ["court", "They went to court."],
  ["cousin", "Her cousin is visiting."],
  ["cover", "Cover the pot."],
  ["cover", "Please cover the pot."],
  ["cover", "Please cover the pot."],
  ["cow", "A cow is in the field."],
  ["cow", "The cow is grazing."],
  ["crack", "There is a crack in the wall."],
  ["crack", "There is a crack in the window."],
  ["craft", "She enjoys craft projects."],
  ["crash", "The car crash was severe."],
  ["crawl", "The baby can crawl."],
  ["crazy", "That idea is crazy."],
  ["cream", "She likes ice cream."],
  ["create", "She likes to create art."],
  ["create", "She likes to create art."],
  ["creature", "A dragon is a mythical creature."],
  ["credit", "She has good credit."],
  ["creek", "They crossed the creek."],
  ["crew", "The crew works hard."],
  ["crime", "Crime is a problem."],
  ["crime", "Crime is a serious problem."],
  ["crisp", "The apple is crisp."],
  ["criteria", "Meet the criteria."],
  ["critic", "He is a film critic."],
  ["crop", "The crop is ready."],
  ["cross", "Be careful when you cross the street."],
  ["cross", "The river was shallow enough to cross."],
  ["crowd", "The crowd cheered loudly."],
  ["crowd", "The crowd cheered."],
  ["crucial", "This is a crucial moment."],
  ["cruel", "The king was cruel to his subjects."],
  ["crush", "She used a stone to crush the garlic."],
  ["cry", "The baby began to cry."],
  ["cry", "The baby began to cry."],
  ["crystal", "The crystal was clear and sparkled in the light."],
  ["cultivate", "They cultivate their crops carefully."],
  ["cultural", "They studied cultural differences."],
  ["culture", "She is interested in learning about other cultures."],
  ["culture", "They enjoy learning about culture."],
  ["cup", "He drank from the cup."],
  ["cup", "He drank from the cup."],
  ["cup", "She drank a cup of tea."],
  ["cupboard", "She put the dishes in the cupboard."],
  ["curious", "The cat was curious about the new toy."],
  ["current", "The current in the river is strong."],
  ["current", "The current is strong."],
  ["current", "The current news is good."],
  ["curtain", "She drew the curtain to let in light."],
  ["curve", "The road had a sharp curve."],
  ["custom", "It is their custom to celebrate together."],
  ["customer", "She is a regular customer."],
  ["cut", "He cut the paper with scissors."],
  ["cut", "She cut the paper."],
  ["cute", "The puppy is very cute."],
  ["cycle", "They cycle to school every day."],
  ["dad", "Her dad is a doctor."],
  ["dad", "Her dad is a teacher."],
  ["dad", "My dad is funny."],
  ["damage", "The storm caused a lot of damage."],
  ["dance", "They love to dance together."],
  ["dance", "They love to dance."],
  ["danger", "She sensed danger and ran away."],
  ["dangerous", "The climb was dangerous."],
  ["dare", "He dared me to jump."],
  ["dark", "It gets dark early in winter."],
  ["dark", "It is dark outside."],
  ["data", "She entered the data into the computer."],
  ["data", "The data was collected last year."],
  ["date", "Today's date is January 1."],
  ["daughter", "Her daughter is five years old."],
  ["daughter", "Her daughter is in kindergarten."],
  ["daughter", "She is their daughter."],
  ["day", "Today is a sunny day."],
  ["day", "Today is a sunny day."],
  ["day", "Today is a sunny day."],
  ["day", "Today is the day the robots take over the kitchen."],
  ["dead", "The battery is dead."],
  ["dead", "The plant is dead."],
  ["dead", "The plant is dead."],
  ["deal", "They made a deal."],
  ["deal", "They made a good deal."],
  ["deal", "We made a deal."],
  ["dear", "He is my dear friend."],
  ["dear", "My dear friend."],
  ["death", "She feared death."],
  ["death", "She feared death."],
  ["debate", "They had a debate about the issue."],
  ["debate", "They had a debate about the topic."],
  ["debt", "He is in debt."],
  ["decade", "A decade is ten years."],
  ["decade", "He has been a teacher for a decade."],
  ["decide", "She needs to decide."],
  ["decide", "She will decide soon."],
  ["decide", "She will decide tomorrow."],
  ["decimal", "He wrote the number as a decimal."],
  ["decimal", "The decimal point is here."],
  ["decision", "She made a decision."],
  ["declare", "She will declare her decision."],
  ["decorate", "They will decorate the room."],
  ["decrease", "Sales have decreased."],
  ["deep", "The lake is deep."],
  ["deep", "The lake is very deep."],
  ["deep", "The water is deep."],
  ["deer", "A deer is in the forest."],
  ["deer", "A deer is in the forest."],
  ["defeat", "The team suffered a defeat."],
  ["defend", "He will defend his title."],
  ["defense", "The team played strong defense."],
  ["define", "Can you define this word?"],
  ["definite", "I need a definite answer."],
  ["degree", "She has a degree in biology."],
  ["degree", "She has a degree in biology."],
  ["delay", "There was a delay in the flight."],
  ["deliver", "They deliver the newspapers."],
  ["demand", "There is high demand."],
  ["Democrat", "He is a Democrat."],
  ["demonstrate", "He will demonstrate the procedure."],
  ["deny", "He will deny the accusation."],
  ["department", "She works in the sales department."],
  ["depend", "I depend on my friends."],
  ["depend", "I depend on you."],
  ["deposit", "He made a deposit at the bank."],
  ["describe", "Can you describe it?"],
  ["describe", "Can you describe it?"],
  ["describe", "Can you describe the book?"],
  ["desert", "The desert is hot and dry."],
  ["desert", "The desert is hot."],
  ["deserve", "You deserve a reward."],
  ["design", "She likes to design clothes."],
  ["design", "She likes to design clothes."],
  ["design", "She likes to design clothes."],
  ["desk", "He works at a desk."],
  ["desk", "She sat at the desk."],
  ["despite", "Despite the rain, they went out."],
  ["despite", "They went for a walk despite the rain."],
  ["destroy", "They will destroy the old building."],
  ["detail", "He explained in detail."],
  ["detail", "He explained in detail."],
  ["detail", "He explained the detail."],
  ["detect", "They will detect the error."],
  ["determine", "She will determine the winner."],
  ["determine", "The test will determine your level."],
  ["determine", "They will determine the winner."],
  ["develop", "She wants to develop her skills."],
  ["develop", "They will develop the land."],
  ["develop", "We need to develop a plan."],
  ["development", "This is a new development."],
  ["device", "She uses a device to track her steps."],
  ["devote", "He will devote time to his hobbies."],
  ["diagram", "She drew a diagram."],
  ["dial", "Turn the dial to the right."],
  ["dialogue", "They had a friendly dialogue."],
  ["diary", "She writes in her diary every night."],
  ["dictionary", "Look it up in the dictionary."],
  ["dictionary", "Look up the word in the dictionary."],
  ["did", "Did you see the penguin in the tuxedo?"],
  ["die", "The plant will die without water."],
  ["die", "The plant will die without water."],
  ["die", "The plant will die without water."],
  ["diet", "She is on a diet."],
  ["differ", "They differ in opinion."],
  ["difference", "There is a big difference."],
  ["difference", "There is a big difference."],
  ["different", "They have different ideas."],
  ["different", "They have different tastes."],
  ["different", "They have different tastes."],
  ["difficult", "The puzzle is difficult."],
  ["difficult", "The test was difficult."],
  ["difficult", "The test was difficult."],
  ["dig", "They will dig a hole."],
  ["dinner", "We had chicken for dinner."],
  ["dinner", "We had chicken for dinner."],
  ["direct", "Please give direct instructions."],
  ["direct", "Please give me direct instructions."],
  ["direction", "Can you give me the direction?"],
  ["direction", "She gave me the direction."],
  ["direction", "Which direction is north?"],
  ["director", "She is the director of the play."],
  ["dirt", "The dirt is dry."],
  ["dirty", "Her clothes are dirty."],
  ["disagree", "They disagree on the topic."],
  ["disappear", "The magician will disappear."],
  ["disappoint", "Do not disappoint her."],
  ["disaster", "The flood was a disaster."],
  ["discipline", "She needs more discipline."],
  ["discover", "He wants to discover new places."],
  ["discover", "She will discover the truth."],
  ["discover", "They will discover the truth."],
  ["discuss", "They discuss the book."],
  ["discuss", "They discuss the book."],
  ["discuss", "They discussed the problem."],
  ["discussion", "They had a discussion about the topic."],
  ["disease", "He is fighting the disease."],
  ["disease", "She is recovering from a disease."],
  ["dish", "The dish is clean."],
  ["dismiss", "They dismissed the class."],
  ["display", "The store has a window display."],
  ["distance", "The distance is far."],
  ["distance", "The distance to the park is short."],
  ["divide", "Let's divide the cake."],
  ["divide", "They will divide the cake."],
  ["division", "She solved the division problem."],
  ["do", "Do you like my superhero cape?"],
  ["do", "Do you like pizza?"],
  ["do", "Do you like pizza?"],
  ["doctor", "She is a doctor."],
  ["doctor", "She went to the doctor for a check-up."],
  ["doctor", "The doctor is kind."],
  ["document", "She signed the document."],
  ["does", "She does her homework."],
  ["dog", "The dog barked loudly."],
  ["dog", "The dog is barking."],
  ["dog", "The dog is barking."],
  ["dog", "The dog wore a tutu and danced ballet."],
  ["dollar", "The book costs five dollars."],
  ["don't", "Don't eat the last marshmallow; it's magical!"],
  ["don't", "Please don't touch that."],
  ["done", "He is done with his homework."],
  ["done", "The work is done."],
  ["donkey", "They rode a donkey."],
  ["door", "Close the door."],
  ["door", "Close the door."],
  ["door", "She opened the door."],
  ["dot", "She drew a dot on the paper."],
  ["double", "She needs double the amount."],
  ["double", "She needs double the amount."],
  ["doubt", "I doubt it will rain."],
  ["down", "He fell down."],
  ["down", "He fell down."],
  ["down", "He slid down the rainbow slide."],
  ["down", "The ball rolled down the hill."],
  ["dozen", "She bought a dozen eggs."],
  ["draft", "He wrote a draft of the letter."],
  ["drag", "She had to drag the box."],
  ["drama", "The play is a drama."],
  ["draw", "She likes to draw pictures."],
  ["draw", "She likes to draw."],
  ["draw", "She likes to draw."],
  ["dream", "He had a strange dream."],
  ["dream", "He had a strange dream."],
  ["dream", "She had a strange dream."],
  ["dress", "She wore a blue dress."],
  ["dress", "She wore a red dress."],
  ["drink", "Can I have a drink?"],
  ["drink", "She wants a drink of water."],
  ["drive", "He will drive the car."],
  ["drive", "He will drive the car."],
  ["drive", "She can drive a car."],
  ["drop", "Be careful not to drop it."],
  ["drop", "Please don't drop the vase."],
  ["drop", "She dropped the plate."],
  ["drug", "The doctor gave him a drug."],
  ["dry", "The clothes are dry."],
  ["dry", "The towel is dry."],
  ["duck", "A duck is swimming in the pond."],
  ["duck", "A duck is swimming."],
  ["due", "The assignment is due tomorrow."],
  ["during", "During the day, she works."],
  ["during", "During the day, she works."],
  ["during", "She reads during lunch."],
  ["dust", "The shelf is covered in dust."],
  ["duty", "It is his duty to help."],
  ["each", "Each child has a toy."],
  ["each", "Each child has a toy."],
  ["each", "Each of the bunnies wore glasses."],
  ["each", "Each student has a book."],
  ["eager", "She is eager to learn."],
  ["ear", "He whispered in her ear."],
  ["ear", "She whispered in his ear."],
  ["early", "She woke up early."],
  ["early", "We woke up early."],
  ["early", "We woke up early."],
  ["earn", "She earns a good salary."],
  ["earth", "The earth is round."],
  ["earth", "The earth is round."],
  ["ease", "She finished the task with ease."],
  ["ease", "She finished with ease."],
  ["east", "The sun rises in the east."],
  ["east", "The sun rises in the east."],
  ["east", "The sun rises in the east."],
  ["easy", "The test was easy."],
  ["easy", "The test was easy."],
  ["eat", "I like to eat apples."],
  ["eat", "I like to eat apples."],
  ["eat", "I like to eat spaghetti with a fork in each hand."],
  ["eat", "She likes to eat apples."],
  ["echo", "Her voice echoed in the cave."],
  ["economic", "They discussed economic policies."],
  ["economy", "The economy is growing."],
  ["economy", "The economy is growing."],
  ["edge", "He stood at the edge of the cliff."],
  ["edge", "He stood at the edge."],
  ["edge", "She stood at the edge of the cliff."],
  ["edition", "She bought the latest edition."],
  ["education", "Education is important."],
  ["education", "Education is important."],
  ["education", "Education is important."],
  ["effect", "The effect was immediate."],
  ["effect", "The medicine had a positive effect."],
  ["effect", "The medicine had an effect."],
  ["effort", "She made an effort to improve."],
  ["effort", "She made an effort."],
  ["egg", "She fried an egg."],
  ["egg", "She fried an egg."],
  ["eight", "She has eight crayons."],
  ["eight", "She has eight crayons."],
  ["eight", "There are eight apples."],
  ["either", "You can choose either option."],
  ["either", "You can choose either."],
  ["either", "You can choose either."],
  ["elbow", "She hurt her elbow."],
  ["elder", "He is the elder brother."],
  ["election", "The election is next week."],
  ["electric", "The car is electric."],
  ["electric", "The car is electric."],
  ["elegant", "The dress is elegant."],
  ["element", "Oxygen is an element."],
  ["element", "This is a chemical element."],
  ["elevator", "Take the elevator to the third floor."],
  ["eleven", "She is eleven years old."],
  ["else", "What else do you need?"],
  ["else", "What else do you need?"],
  ["else", "What else do you need?"],
  ["embarrass", "He will embarrass himself."],
  ["emerge", "The butterfly will emerge."],
  ["emotion", "She showed no emotion."],
  ["employ", "The company will employ more workers."],
  ["employee", "She is an employee at the company."],
  ["empty", "The box is empty."],
  ["enable", "This will enable her to succeed."],
  ["encounter", "They had an encounter."],
  ["encourage", "She will encourage him."],
  ["end", "This is the end of the movie."],
  ["end", "This is the end of the story about the flying pancakes."],
  ["end", "This is the end of the story."],
  ["end", "This is the end of the story."],
  ["enemy", "They faced their enemy."],
  ["enemy", "They faced their enemy."],
  ["energy", "She has a lot of energy."],
  ["energy", "The children have a lot of energy."],
  ["energy", "The children have a lot of energy."],
  ["engine", "The car has a powerful engine."],
  ["engine", "The engine is loud."],
  ["enjoy", "They enjoy playing soccer."],
  ["enjoy", "They enjoy playing together."],
  ["enormous", "The elephant is enormous."],
  ["enough", "She has enough money."],
  ["enough", "She has enough money."],
  ["enough", "She has enough money."],
  ["enter", "Please enter the room."],
  ["enter", "Please enter the room."],
  ["enter", "Please enter the room."],
  ["entertain", "He will entertain the guests."],
  ["entire", "She read the entire book."],
  ["entire", "She read the entire book."],
  ["entrance", "The entrance is open."],
  ["envelope", "She sealed the envelope."],
  ["environment", "We need to protect the environment."],
  ["equal", "The parts are equal."],
  ["equal", "The parts are equal."],
  ["equipment", "The equipment is new."],
  ["era", "This era is known for its art."],
  ["error", "He made an error."],
  ["escape", "They managed to escape."],
  ["especially", "I especially like the red one."],
  ["especially", "I especially like the red one."],
  ["especially", "She likes chocolate, especially dark chocolate."],
  ["essay", "She wrote an essay."],
  ["essential", "Water is essential for life."],
  ["establish", "They established a new school."],
  ["establish", "They will establish a new school."],
  ["estimate", "They estimate the cost."],
  ["etc", "She likes apples, oranges, etc."],
  ["evaluate", "They will evaluate the project."],
  ["even", "She can even juggle flamingos."],
  ["even", "She is even taller than him."],
  ["even", "She is even taller than him."],
  ["even", "The road is even."],
  ["evening", "Good evening, everyone."],
  ["evening", "Good evening, everyone."],
  ["evening", "Good evening!"],
  ["event", "The event was fun."],
  ["event", "The event was fun."],
  ["event", "They attended the event."],
  ["eventually", "She will eventually learn."],
  ["ever", "Have you ever been there?"],
  ["ever", "Have you ever been there?"],
  ["ever", "Have you ever been to Paris?"],
  ["every", "Every day is a new day."],
  ["every", "Every day is a new day."],
  ["every", "Every fish in the pond sings in the morning."],
  ["every", "Every student has a laptop."],
  ["everybody", "Everybody likes him."],
  ["everyday", "It is an everyday item."],
  ["everyone", "Everyone is here."],
  ["everything", "Everything is ready."],
  ["everywhere", "She looked everywhere."],
  ["evidence", "The police found evidence."],
  ["evidence", "There is no evidence."],
  ["exact", "The answer must be exact."],
  ["exact", "The measurements must be exact."],
  ["exactly", "That is exactly right."],
  ["exactly", "That's exactly what I mean."],
  ["examine", "The doctor will examine you."],
  ["example", "This is an example of a very wiggly worm."],
  ["example", "This is an example sentence."],
  ["example", "This is an example sentence."],
  ["example", "This is an example sentence."],
  ["excellent", "The service was excellent."],
  ["except", "Everyone except him came."],
  ["except", "Everyone except him came."],
  ["exchange", "They will exchange gifts."],
  ["excite", "The movie will excite you."],
  ["excited", "She is excited about the trip."],
  ["excuse", "Please excuse me."],
  ["executive", "He is an executive at the company."],
  ["exercise", "Exercise is good for health."],
  ["exercise", "Exercise is good for health."],
  ["exist", "Dragons do not exist."],
  ["exist", "Dragons do not exist."],
  ["expand", "They plan to expand the store."],
  ["expect", "I expect you to come."],
  ["expect", "I expect you to come."],
  ["expect", "They expect good weather tomorrow."],
  ["expense", "The expense is too high."],
  ["experience", "She has a lot of experience."],
  ["experience", "She has a lot of experience."],
  ["experience", "She has experience in teaching."],
  ["experiment", "They conducted an experiment."],
  ["experiment", "They conducted an experiment."],
  ["expert", "He is an expert in math."],
  ["explain", "Can you explain the rules?"],
  ["explain", "Can you explain the rules?"],
  ["explain", "Can you explain the rules?"],
  ["explode", "The bomb will explode."],
  ["explore", "They like to explore the forest."],
  ["export", "They export goods."],
  ["expose", "He will expose the truth."],
  ["express", "She likes to express herself."],
  ["extend", "They plan to extend the deadline."],
  ["extra", "She has extra pencils."],
  ["eye", "She has blue eyes."],
  ["eye", "She has blue eyes."],
  ["eye", "She has blue eyes."],
  ["fabric", "The fabric is soft."],
  ["face", "He washed his face."],
  ["face", "He washed his face."],
  ["face", "She has a kind face."],
  ["facility", "The facility is new."],
  ["fact", "It's a fact that the earth is round."],
  ["fact", "It's a fact that the earth is round."],
  ["fact", "It's a fact that the Earth orbits the Sun."],
  ["factor", "Consider this factor."],
  ["factor", "Consider this factor."],
  ["factory", "He works in a factory."],
  ["fade", "The color will fade."],
  ["fail", "He will not fail the test."],
  ["fail", "He will not fail."],
  ["faint", "He might faint from the heat."],
  ["fair", "She has a fair chance."],
  ["faith", "She has faith in him."],
  ["fall", "The leaves fall in autumn."],
  ["fall", "The leaves fall in autumn."],
  ["false", "The statement is false."],
  ["fame", "She gained fame."],
  ["familiar", "She looks familiar."],
  ["family", "My family is big."],
  ["family", "My family is big."],
  ["family", "My family is big."],
  ["famous", "She is a famous singer."],
  ["famous", "She is a famous singer."],
  ["fan", "The fan is on."],
  ["fancy", "She wore a fancy dress."],
  ["fantasy", "She enjoys reading fantasy novels."],
  ["far", "The park is far from here."],
  ["far", "The park is not far from here."],
  ["far", "The school is far."],
  ["farm", "They live on a farm."],
  ["farm", "They live on a farm."],
  ["farmer", "The farmer grows vegetables."],
  ["farmer", "The farmer grows vegetables."],
  ["fashion", "She likes to follow fashion trends."],
  ["fast", "The car is fast."],
  ["fast", "The car is fast."],
  ["fast", "The car is very fast."],
  ["fat", "The cat is fat."],
  ["fatal", "The accident was fatal."],
  ["father", "Her father is a doctor."],
  ["father", "My father can make his ears wiggle."],
  ["father", "My father is a doctor."],
  ["father", "My father is a doctor."],
  ["fault", "It was her fault."],
  ["favor", "Can you do me a favor?"],
  ["favorite", "Ice cream is my favorite dessert."],
  ["fear", "He has a fear of heights."],
  ["fear", "She has a fear of heights."],
  ["feather", "The bird lost a feather."],
  ["feature", "The phone has new features."],
  ["federal", "He works for the federal government."],
  ["federal", "She works for the federal government."],
  ["fee", "The entry fee is $10."],
  ["feed", "She likes to feed the birds."],
  ["feed", "She likes to feed the birds."],
  ["feel", "I feel happy."],
  ["feel", "I feel happy."],
  ["feel", "She feels happy today."],
  ["feeling", "She has a strange feeling."],
  ["feeling", "She has a strange feeling."],
  ["feet", "Her feet are cold."],
  ["fell", "He fell down."],
  ["fellow", "He is a fellow student."],
  ["felt", "She felt sad."],
  ["felt", "She felt sad."],
  ["female", "The female bird is smaller."],
  ["fence", "The garden has a fence."],
  ["few", "She has a few friends."],
  ["few", "She has a few friends."],
  ["few", "There are a few cookies left."],
  ["field", "The field is full of flowers."],
  ["field", "They played in the field."],
  ["field", "They played in the field."],
  ["fierce", "The lion is fierce."],
  ["fifteen", "She is fifteen years old."],
  ["fifth", "This is her fifth attempt."],
  ["fifty", "She has fifty marbles."],
  ["fig", "She ate a fig."],
  ["fight", "They had a fight."],
  ["fight", "They had a fight."],
  ["fight", "They had a fight."],
  ["figure", "She drew a figure."],
  ["figure", "The figure is correct."],
  ["fill", "Can you fill the glass with water?"],
  ["fill", "Fill the cup with water."],
  ["fill", "Please fill the glass with water."],
  ["film", "They watched a film."],
  ["film", "They watched a film."],
  ["final", "This is the final chapter."],
  ["final", "This is the final round."],
  ["final", "This is the final round."],
  ["finally", "She finally finished her homework."],
  ["finally", "She finally finished her work."],
  ["finance", "He studied finance."],
  ["financial", "They need financial assistance."],
  ["find", "I can't find my keys."],
  ["find", "I can't find my keys."],
  ["find", "I can't find my keys."],
  ["find", "I can't find my socks because they're invisible."],
  ["fine", "Everything is fine."],
  ["fine", "The weather is fine."],
  ["fine", "The weather is fine."],
  ["finger", "She cut her finger."],
  ["finger", "She hurt her finger."],
  ["finger", "She hurt her finger."],
  ["finish", "Please finish your homework."],
  ["finish", "Please finish your work."],
  ["finish", "She will finish her homework."],
  ["fire", "The fire is hot."],
  ["fire", "The fire is hot."],
  ["fire", "The fire kept them warm."],
  ["firm", "He works at a law firm."],
  ["firm", "The chair is firm."],
  ["first", "She finished first in the race against the snails."],
  ["first", "She finished first in the race."],
  ["first", "She finished first in the race."],
  ["first", "She finished first in the race."],
  ["fish", "There are fish in the pond."],
  ["fish", "We saw a big fish."],
  ["fish", "We saw a big fish."],
  ["fist", "He clenched his fist."],
  ["fit", "The dress fits well."],
  ["five", "She has five pencils."],
  ["five", "She has five pencils."],
  ["five", "She has five pencils."],
  ["fix", "He can fix the car."],
  ["flag", "The flag is red, white, and blue."],
  ["flame", "The candle's flame flickered."],
  ["flash", "There was a flash of lightning."],
  ["flat", "The tire is flat."],
  ["flavor", "She likes the flavor of vanilla."],
  ["flee", "The thief tried to flee."],
  ["flesh", "The flesh of the fruit is sweet."],
  ["flight", "They booked a flight to Paris."],
  ["float", "The boat can float."],
  ["flood", "The flood caused damage."],
  ["floor", "The floor is clean."],
  ["floor", "The floor is clean."],
  ["floor", "The floor is clean."],
  ["flour", "She used flour to bake bread."],
  ["flow", "The river's flow is gentle."],
  ["flower", "She picked a flower."],
  ["flower", "She picked a flower."],
  ["fly", "A bird can fly."],
  ["fly", "A bird can fly."],
  ["fly", "A bird can fly."],
  ["focus", "She needs to focus on her work."],
  ["focus", "Try to focus on your work."],
  ["fog", "The fog made it hard to see."],
  ["fold", "Please fold the laundry."],
  ["follow", "Follow the instructions."],
  ["follow", "Follow the instructions."],
  ["follow", "Please follow the rules."],
  ["food", "She likes all kinds of food."],
  ["food", "She likes all kinds of food."],
  ["food", "The food is delicious."],
  ["fool", "He played a trick like a fool."],
  ["foot", "Her foot is sore."],
  ["foot", "Her foot is sore."],
  ["foot", "His foot hurts."],
  ["football", "He likes to play football."],
  ["for", "This gift is for the king of the jellybeans."],
  ["for", "This gift is for you."],
  ["for", "This gift is for you."],
  ["for", "This gift is for you."],
  ["force", "They used force to open the door."],
  ["force", "They used force to open the door."],
  ["force", "They used force to open the door."],
  ["forecast", "The weather forecast is sunny."],
  ["foreign", "He speaks a foreign language."],
  ["forest", "They went hiking in the forest."],
  ["forest", "They went hiking in the forest."],
  ["forget", "Don't forget to bring your book."],
  ["forget", "Don't forget to do your homework."],
  ["forget", "Don't forget to lock the door."],
  ["forgive", "Please forgive me."],
  ["fork", "She used a fork to eat."],
  ["form", "They filled out a form."],
  ["form", "They filled out a form."],
  ["form", "They filled out the form."],
  ["formal", "The event is formal."],
  ["former", "She is a former student."],
  ["fortune", "He made a fortune."],
  ["forty", "She bought forty apples."],
  ["forward", "She took a step forward."],
  ["forward", "She took a step forward."],
  ["found", "I found a coin on the ground."],
  ["found", "I found a coin on the ground."],
  ["found", "I found a pirate hat in my cereal box."],
  ["four", "The table has four legs."],
  ["four", "The table has four legs."],
  ["four", "There are four seasons."],
  ["fourteen", "She is fourteen years old."],
  ["fox", "A fox is clever."],
  ["fox", "A fox is clever."],
  ["frame", "The picture has a frame."],
  ["free", "The tickets are free."],
  ["free", "The tickets are free."],
  ["free", "The tickets are free."],
  ["freedom", "They fought for freedom."],
  ["freeze", "The lake will freeze in winter."],
  ["frequent", "She is a frequent visitor."],
  ["fresh", "The bread is fresh."],
  ["friend", "She is my best friend."],
  ["friend", "She is my best friend."],
  ["friend", "She is my best friend."],
  ["friendly", "The dog is friendly."],
  ["friendly", "The dog is friendly."],
  ["friendship", "Their friendship is strong."],
  ["frog", "A frog can jump."],
  ["frog", "A frog can jump."],
  ["from", "She is from Canada."],
  ["from", "She is from Canada."],
  ["from", "She is from Canada."],
  ["from", "She is from the land of dancing gummy bears."],
  ["front", "She stood in front of the class."],
  ["front", "Stand in front of the class."],
  ["front", "Stand in front of the class."],
  ["frost", "The window had frost."],
  ["frown", "She had a frown on her face."],
  ["fruit", "She likes fruit."],
  ["fruit", "She likes fruit."],
  ["fuel", "The car needs fuel."],
  ["full", "The box is full of toys."],
  ["full", "The box is full."],
  ["full", "The box is full."],
  ["fun", "Playing is fun."],
  ["fun", "Playing is fun."],
  ["fun", "Playing with talking toys is so much fun."],
  ["function", "The function is useful."],
  ["fund", "They raised a fund."],
  ["fund", "They raised funds for the charity."],
  ["funny", "He told a funny joke."],
  ["furniture", "They bought new furniture."],
  ["further", "Let's discuss this further."],
  ["future", "Think about your future."],
  ["future", "Think about your future."],
  ["gain", "She will gain knowledge."],
  ["galaxy", "The galaxy has many stars."],
  ["gallery", "They visited an art gallery."],
  ["game", "The game is exciting."],
  ["game", "The game is exciting."],
  ["game", "The game is to find the laughing llama."],
  ["game", "They played a fun game."],
  ["gap", "There is a gap in the fence."],
  ["garage", "The car is in the garage."],
  ["garden", "She planted flowers in the garden."],
  ["garden", "The garden has many flowers."],
  ["garden", "The garden has many flowers."],
  ["garlic", "She used garlic in the recipe."],
  ["gas", "The car needs gas."],
  ["gas", "The car needs gas."],
  ["gate", "The gate is open."],
  ["gather", "They will gather at the park."],
  ["gave", "She gave me a gift."],
  ["gave", "She gave me a gift."],
  ["general", "In general, it was a good day."],
  ["general", "In general, it was a good day."],
  ["general", "In general, the weather is nice."],
  ["generate", "They generate electricity."],
  ["generation", "This is a new generation."],
  ["generous", "She is generous with her time."],
  ["gentle", "Be gentle with the kitten."],
  ["gentle", "Be gentle with the kitten."],
  ["genuine", "Her smile is genuine."],
  ["gesture", "She made a kind gesture."],
  ["get", "Can you get the ball?"],
  ["get", "Can you get the book?"],
  ["get", "Can you get the flying car?"],
  ["gift", "He gave her a gift."],
  ["giggle", "The baby let out a giggle."],
  ["girl", "The girl built a rocket ship in her backyard."],
  ["girl", "The girl is dancing."],
  ["girl", "The girl is dancing."],
  ["girl", "The girl is reading a book."],
  ["give", "Give me the book."],
  ["give", "Give me the book."],
  ["give", "Give me the recipe for that floating cookie."],
  ["give", "Please give me your hand."],
  ["glad", "I am glad you came."],
  ["glad", "I am glad you came."],
  ["glass", "She drank a glass of water."],
  ["glass", "The glass is full of water."],
  ["glass", "The glass is full of water."],
  ["globe", "She has a globe on her desk."],
  ["globe", "She has a globe on her desk."],
  ["glory", "The team won glory."],
  ["glove", "She wore a glove."],
  ["glow", "The stars glow at night."],
  ["go", "Let's go to the park."],
  ["go", "Let's go to the park."],
  ["go", "Let's go to the party on the moon."],
  ["goal", "Her goal is to become a teacher."],
  ["goal", "His goal is to run a marathon."],
  ["goat", "A goat is grazing."],
  ["god", "They prayed to their god."],
  ["gold", "The ring is gold."],
  ["gold", "The ring is gold."],
  ["good", "She is a good magician with a hat full of tricks."],
  ["good", "She is a good student."],
  ["good", "She is a good student."],
  ["good", "She is a good student."],
  ["goodbye", "They said goodbye."],
  ["goods", "They sell goods."],
  ["goose", "A goose is swimming."],
  ["goose", "A goose is swimming."],
  ["got", "I got a new toy."],
  ["got", "I got a new toy."],
  ["govern", "They govern the country."],
  ["government", "The government passed a law."],
  ["government", "The government passed a new law."],
  ["grab", "She tried to grab the last cookie."],
  ["grace", "She moved with grace."],
  ["grade", "She got a good grade."],
  ["grain", "They grow grain on the farm."],
  ["grand", "The house is grand."],
  ["grandfather", "My grandfather is old."],
  ["grandfather", "My grandfather is old."],
  ["grandmother", "My grandmother bakes cookies."],
  ["grandmother", "My grandmother bakes cookies."],
  ["grant", "They will grant her request."],
  ["grape", "She ate a grape."],
  ["grass", "The grass is green."],
  ["grass", "The grass is green."],
  ["grateful", "I am grateful for your help."],
  ["grave", "They visited the grave."],
  ["gray", "The sky is gray."],
  ["great", "They had a great time."],
  ["great", "We had a great time at the underwater concert."],
  ["great", "We had a great time."],
  ["great", "We had a great time."],
  ["greater", "This is a greater challenge."],
  ["greatest", "She is the greatest singer."],
  ["green", "The grass is green."],
  ["green", "The grass is green."],
  ["green", "The grass is green."],
  ["green", "The green frog sang a lullaby."],
  ["greet", "They greet each other."],
  ["grew", "The plant grew tall."],
  ["grew", "The plant grew tall."],
  ["grief", "She felt grief."],
  ["grin", "He had a big grin."],
  ["grip", "She has a strong grip."],
  ["groan", "He let out a groan."],
  ["ground", "He sat on the ground."],
  ["ground", "The ground is wet."],
  ["ground", "The ground is wet."],
  ["group", "They are in a group."],
  ["group", "They are in a group."],
  ["group", "They are in a study group."],
  ["grow", "Plants grow with water."],
  ["grow", "Plants grow with water."],
  ["grow", "Plants need water to grow."],
  ["growth", "The company has seen growth."],
  ["growth", "The plant's growth is fast."],
  ["guarantee", "They guarantee satisfaction."],
  ["guard", "The guard watched closely."],
  ["guess", "Can you guess my age?"],
  ["guess", "Can you guess my age?"],
  ["guess", "Can you guess my age?"],
  ["guest", "They have a guest."],
  ["guide", "He will guide us."],
  ["guide", "He will guide us."],
  ["guilty", "She felt guilty."],
  ["gun", "The police officer had a gun."],
  ["gun", "They heard a gunshot."],
  ["gun", "They heard a gunshot."],
  ["guy", "That guy is my friend."],
  ["habit", "She has a good habit."],
  ["had", "He had a dream about a surfing monkey."],
  ["had", "She had a pet cat."],
  ["hair", "Her hair is long."],
  ["hair", "Her hair is long."],
  ["hair", "Her hair is long."],
  ["half", "She ate half of the sandwich."],
  ["half", "She ate half of the sandwich."],
  ["half", "She ate half of the sandwich."],
  ["hall", "They walked down the hall."],
  ["hammer", "He used a hammer."],
  ["hand", "He raised his hand."],
  ["hand", "He raised his hand."],
  ["hand", "He raised his hand."],
  ["handle", "She turned the handle."],
  ["hang", "He will hang the picture."],
  ["hang", "She will hang the picture."],
  ["happen", "What will happen next?"],
  ["happen", "What will happen next?"],
  ["happen", "What will happen next?"],
  ["happy", "She is happy because the sun is made of cheese."],
  ["happy", "She is very happy."],
  ["happy", "She is very happy."],
  ["happy", "She is very happy."],
  ["harbor", "The boat is in the harbor."],
  ["hard", "The test was hard."],
  ["hard", "The test was hard."],
  ["hard", "The test was hard."],
  ["hardly", "She can hardly wait."],
  ["harm", "He means no harm."],
  ["harmony", "They live in harmony."],
  ["harvest", "They will harvest the crops."],
  ["has", "He has a big dog."],
  ["has", "He has a collection of invisible stamps."],
  ["hat", "He wore a red hat."],
  ["hat", "He wore a red hat."],
  ["hate", "She hates spinach."],
  ["have", "We have a dog."],
  ["have", "We have a new car."],
  ["have", "We have a new car."],
  ["have", "We have a pet dragon in the attic."],
  ["he", "He is my brother."],
  ["he", "He is my brother."],
  ["he", "He is my brother."],
  ["he", "He is the king of the bouncing balls."],
  ["head", "She has a headache."],
  ["head", "She has a headache."],
  ["head", "She has a headache."],
  ["health", "Exercise is good for health."],
  ["health", "Exercise is good for health."],
  ["healthy", "She is healthy."],
  ["hear", "Can you hear the music?"],
  ["hear", "Can you hear the music?"],
  ["hear", "Can you hear the music?"],
  ["heard", "I heard a noise."],
  ["heard", "I heard a noise."],
  ["heart", "Her heart is kind."],
  ["heart", "Her heart is kind."],
  ["heart", "Her heart is kind."],
  ["heat", "The heat is intense."],
  ["heat", "The heat is intense."],
  ["heat", "The heat is intense."],
  ["heaven", "They believe in heaven."],
  ["heavy", "The box is heavy."],
  ["heavy", "The box is heavy."],
  ["heavy", "The box is heavy."],
  ["heel", "Her heel hurts."],
  ["height", "The height of the tree is impressive."],
  ["held", "She held the baby."],
  ["held", "She held the baby."],
  ["hello", "Hello, how are you?"],
  ["hello", "Hello, how are you?"],
  ["help", "Can you help me tie my shoes on the moon?"],
  ["help", "Can you help me?"],
  ["help", "Can you help me?"],
  ["help", "Can you help me?"],
  ["hen", "A hen lays eggs."],
  ["her", "Her dress is blue."],
  ["her", "Her dress is made of cotton candy."],
  ["her", "Her dress is pretty."],
  ["her", "Her dress is pretty."],
  ["here", "Please sit here next to the giant marshmallow."],
  ["here", "Please sit here."],
  ["here", "Please sit here."],
  ["here", "Please sit here."],
  ["hero", "He is a hero."],
  ["hers", "This book is hers."],
  ["herself", "She made it herself."],
  ["hi", "Hi, nice to meet you."],
  ["hidden", "The treasure is hidden."],
  ["hide", "The cat likes to hide."],
  ["high", "The mountain is high."],
  ["high", "The mountain is high."],
  ["high", "The mountain is high."],
  ["highlight", "Highlight the important points."],
  ["hill", "They climbed the hill."],
  ["hill", "They climbed the hill."],
  ["him", "He gave him a high-five with a paw."],
  ["him", "I gave him a gift."],
  ["him", "I gave him a gift."],
  ["him", "I gave him a gift."],
  ["himself", "He did it himself."],
  ["himself", "He did it himself."],
  ["hip", "She swayed her hip."],
  ["hire", "They will hire more staff."],
  ["his", "This is his book."],
  ["his", "This is his book."],
  ["his", "This is his book."],
  ["his", "This is his magic wand."],
  ["history", "She loves history."],
  ["history", "She loves history."],
  ["history", "She loves history."],
  ["hit", "He hit the ball."],
  ["hit", "He hit the ball."],
  ["hit", "He hit the ball."],
  ["hold", "Hold my hand."],
  ["hold", "Hold my hand."],
  ["hold", "Hold my hand."],
  ["hole", "There is a hole in the wall."],
  ["hole", "There is a hole in the wall."],
  ["holiday", "They are on holiday."],
  ["hollow", "The tree is hollow."],
  ["home", "I want to go home to my gingerbread house."],
  ["home", "I want to go home."],
  ["home", "I want to go home."],
  ["home", "I want to go home."],
  ["honest", "She is an honest person."],
  ["honey", "She likes honey in her tea."],
  ["honor", "They received an honor."],
  ["hope", "I hope you are well."],
  ["hope", "I hope you are well."],
  ["hope", "I hope you are well."],
  ["horn", "He honked the horn."],
  ["horror", "She felt horror."],
  ["horse", "A horse is running."],
  ["horse", "A horse is running."],
  ["horse", "A horse runs fast."],
  ["hospital", "She works at a hospital."],
  ["hospital", "She works at a hospital."],
  ["host", "He is the host of the party."],
  ["hot", "The soup is hot."],
  ["hot", "The soup is hot."],
  ["hot", "The soup is hot."],
  ["hotel", "They stayed at a hotel."],
  ["hotel", "They stayed at a hotel."],
  ["hour", "It took an hour to finish."],
  ["hour", "We waited for an hour."],
  ["hour", "We waited for an hour."],
  ["house", "The house has a roof made of jellybeans."],
  ["house", "The house is big."],
  ["house", "The house is big."],
  ["house", "Their house is big."],
  ["hover", "The helicopter will hover above the ground."],
  ["how", "How are you?"],
  ["how", "How are you?"],
  ["how", "How are you?"],
  ["how", "How did the hamster learn to juggle?"],
  ["however", "However, I disagree."],
  ["however", "It is raining; however, we can still go."],
  ["huge", "The elephant is huge."],
  ["huge", "The elephant is huge."],
  ["huge", "The elephant is huge."],
  ["human", "She is a kind human."],
  ["human", "She is a kind human."],
  ["humble", "He is very humble."],
  ["humor", "He has a good sense of humor."],
  ["hundred", "She counted to a hundred."],
  ["hundred", "She has a hundred dollars."],
  ["hundred", "There are a hundred pages in the book."],
  ["hung", "He hung the picture."],
  ["hungry", "She felt hungry after the workout."],
  ["hungry", "She is hungry."],
  ["hunt", "They went on a hunt."],
  ["hunter", "The hunter tracked the deer."],
  ["hurry", "Please hurry up."],
  ["hurt", "He hurt his knee."],
  ["husband", "Her husband is a doctor."],
  ["husband", "Her husband is a doctor."],
  ["hut", "They built a hut in the woods."],
  ["I", "I like ice cream."],
  ["I", "I like pizza."],
  ["I", "I like to dance with my robot."],
  ["ice", "The ice is cold."],
  ["ice", "The ice is melting."],
  ["idea", "She has a good idea."],
  ["idea", "She has a good idea."],
  ["idea", "She has a great idea."],
  ["ideal", "This place is ideal for a picnic."],
  ["identify", "Can you identify the problem?"],
  ["if", "If it rains, we will sing with the frogs."],
  ["if", "If it rains, we will stay inside."],
  ["if", "If it rains, we will stay inside."],
  ["if", "If it rains, we will stay inside."],
  ["ill", "He is feeling ill."],
  ["ill", "He is feeling ill."],
  ["illustrate", "She will illustrate the book."],
  ["image", "She took an image of the sunset."],
  ["image", "The image is clear."],
  ["imagine", "Imagine a world without war."],
  ["imagine", "Imagine a world without war."],
  ["imitate", "Children often imitate adults."],
  ["immediate", "She needs immediate help."],
  ["impact", "The impact was great."],
  ["impact", "The impact was strong."],
  ["important", "Education is important."],
  ["important", "Education is important."],
  ["important", "Education is important."],
  ["impose", "They will impose a new law."],
  ["impossible", "Nothing is impossible."],
  ["impress", "He wants to impress his friends."],
  ["improve", "She wants to improve her grades."],
  ["improve", "She wants to improve her skills."],
  ["impulse", "He acted on impulse."],
  ["in", "The cat is in the box."],
  ["in", "The cat is in the box."],
  ["in", "The cat is in the box."],
  ["in", "The cat is in the magician's hat."],
  ["inch", "The book is an inch thick."],
  ["include", "Please include your phone number."],
  ["include", "Please include your phone number."],
  ["include", "The price includes tax."],
  ["including", "He read all the books, including the new one."],
  ["income", "She has a steady income."],
  ["increase", "They want to increase sales."],
  ["increase", "They want to increase sales."],
  ["indeed", "Indeed, it is a good idea."],
  ["indeed", "Indeed, it is a good idea."],
  ["indicate", "The sign indicates the direction."],
  ["indicate", "The sign will indicate the direction."],
  ["individual", "Each individual is unique."],
  ["individual", "Each individual is unique."],
  ["industry", "She works in the tech industry."],
  ["industry", "She works in the tech industry."],
  ["infant", "The infant is sleeping."],
  ["infect", "The virus can infect humans."],
  ["inform", "Please inform me of any changes."],
  ["information", "She gave me the information."],
  ["information", "She gave me the information."],
  ["inject", "The nurse will inject the medicine."],
  ["injure", "He did not want to injure anyone."],
  ["injury", "He has a knee injury."],
  ["innocent", "She is innocent of the crime."],
  ["input", "Please input your data."],
  ["insect", "The insect landed on the flower."],
  ["insert", "Insert the key into the lock."],
  ["inside", "She is inside the house."],
  ["inside", "The cat is inside the house."],
  ["insist", "He will insist on helping."],
  ["inspect", "They will inspect the building."],
  ["inspire", "Her story will inspire you."],
  ["install", "They will install the software."],
  ["instance", "For instance, she likes apples."],
  ["instead", "Let's go instead."],
  ["instead", "Let's go to the park instead of the mall."],
  ["institution", "The institution offers many courses."],
  ["instruct", "She will instruct the class."],
  ["instrument", "He plays a musical instrument."],
  ["insult", "Do not insult anyone."],
  ["insurance", "He has health insurance."],
  ["integrate", "We need to integrate new methods."],
  ["intend", "She does not intend to stay."],
  ["interest", "She has an interest in art."],
  ["interest", "She has an interest in art."],
  ["interesting", "The book is interesting."],
  ["interesting", "The book is interesting."],
  ["interfere", "Do not interfere in their matters."],
  ["interior", "The interior is beautiful."],
  ["internal", "She has internal injuries."],
  ["international", "She likes international cuisine."],
  ["international", "She likes international cuisine."],
  ["internet", "She uses the internet for research."],
  ["interval", "Take a short interval."],
  ["interview", "She has a job interview."],
  ["interview", "She has a job interview."],
  ["into", "She went into the garden of talking flowers."],
  ["into", "She went into the room."],
  ["into", "She went into the room."],
  ["into", "She went into the room."],
  ["introduce", "Please introduce yourself."],
  ["invent", "He likes to invent things."],
  ["invest", "They invest in stocks."],
  ["investigate", "They will investigate the case."],
  ["investment", "He made a good investment."],
  ["invite", "She will invite her friends."],
  ["involve", "The game involves many players."],
  ["involve", "The game will involve many players."],
  ["iron", "The gate is made of iron."],
  ["iron", "The gate is made of iron."],
  ["is", "She is my friend."],
  ["is", "She is my friend."],
  ["is", "This is my talking parrot."],
  ["island", "They visited the island."],
  ["island", "We visited the island."],
  ["issue", "They discussed the issue."],
  ["it", "It is a magical day today."],
  ["it", "It is a sunny day."],
  ["it", "It is a sunny day."],
  ["it", "It is a sunny day."],
  ["item", "She bought a new item."],
  ["item", "She found the missing item."],
  ["its", "The dog wagged its tail to the beat of the music."],
  ["its", "The dog wagged its tail."],
  ["its", "The dog wagged its tail."],
  ["its", "The dog wagged its tail."],
  ["itself", "The cat cleaned itself."],
  ["itself", "The cat cleaned itself."],
  ["jacket", "He wore a jacket."],
  ["jacket", "He wore a jacket."],
  ["jail", "The criminal is in jail."],
  ["jam", "She spread jam on the bread."],
  ["jar", "She put cookies in the jar."],
  ["jaw", "Her jaw hurts."],
  ["jaywalk", "Do not jaywalk across the street."],
  ["jealous", "She felt jealous of her friend's success."],
  ["jeans", "He wore jeans and a T-shirt."],
  ["jelly", "She loves jelly on toast."],
  ["jewel", "The ring has a precious jewel."],
  ["job", "He has a job."],
  ["job", "He has a new job."],
  ["job", "He has a new job."],
  ["jog", "They like to jog in the morning."],
  ["join", "Join the club."],
  ["join", "Join the club."],
  ["join", "Join the club."],
  ["joke", "He told a funny joke."],
  ["journal", "She writes in her journal every day."],
  ["journey", "They went on a journey."],
  ["joy", "She felt great joy."],
  ["judge", "The judge made a decision."],
  ["jug", "She poured water from the jug."],
  ["juice", "She drank orange juice."],
  ["jump", "The kids like to jump over the rainbow."],
  ["jump", "The kids like to jump."],
  ["jump", "The kids like to jump."],
  ["jungle", "They explored the jungle."],
  ["junior", "He is a junior student."],
  ["jury", "The jury made a verdict."],
  ["just", "She just arrived."],
  ["just", "She just left."],
  ["just", "She just left."],
  ["just", "She just saw a flying pig."],
  ["justice", "They demand justice."],
  ["kangaroo", "A kangaroo hops fast."],
  ["keen", "He is keen on reading."],
  ["keep", "Keep your room clean."],
  ["keep", "Please keep quiet."],
  ["keep", "Please keep the door closed."],
  ["keep", "Please keep the magic wand safe."],
  ["kept", "She kept her promise."],
  ["kettle", "The kettle is boiling."],
  ["key", "He lost the key."],
  ["key", "She lost her house key."],
  ["key", "She lost her key."],
  ["keyboard", "She types on the keyboard."],
  ["kick", "He likes to kick the ball."],
  ["kick", "He likes to kick the ball."],
  ["kid", "The kid is laughing."],
  ["kid", "The kid is playing outside."],
  ["kid", "The kid is playing."],
  ["kidnap", "The movie is about a kidnap."],
  ["kidney", "She has a kidney infection."],
  ["kill", "He did not mean to kill the bug."],
  ["kill", "He tried to kill the bug."],
  ["kill", "The cat tried to kill the mouse."],
  ["kind", "She is a kind person."],
  ["kind", "She is kind to the unicorns."],
  ["kind", "She is very kind."],
  ["kind", "She is very kind."],
  ["kindergarten", "She goes to kindergarten."],
  ["king", "The king ruled the land."],
  ["king", "The king ruled the land."],
  ["kingdom", "They live in a peaceful kingdom."],
  ["kiss", "She gave him a kiss."],
  ["kitchen", "She cooked in the kitchen."],
  ["kitchen", "She cooked in the kitchen."],
  ["kitchen", "She cooks in the kitchen."],
  ["kite", "They flew a kite."],
  ["kitten", "The kitten is cute."],
  ["knee", "He hurt his knee."],
  ["kneel", "They kneel to pray."],
  ["knew", "She knew the answer."],
  ["knife", "Be careful with the knife."],
  ["knit", "She likes to knit sweaters."],
  ["knob", "Turn the doorknob."],
  ["knock", "Please knock on the door."],
  ["knot", "Tie a knot in the rope."],
  ["know", "Do you know the answer?"],
  ["know", "I know the answer."],
  ["know", "I know the answer."],
  ["know", "I know the secret to the floating island."],
  ["knowledge", "She has a lot of knowledge."],
  ["knowledge", "She has a lot of knowledge."],
  ["label", "She read the label."],
  ["labor", "The work required hard labor."],
  ["lack", "There is a lack of water."],
  ["ladder", "He climbed the ladder."],
  ["lady", "The lady is kind."],
  ["lake", "The lake is deep."],
  ["lake", "They swam in the lake."],
  ["lamp", "The lamp is on the table."],
  ["land", "They own a piece of land."],
  ["land", "They own a piece of land."],
  ["land", "They own a piece of land."],
  ["language", "She speaks three languages."],
  ["language", "She speaks three languages."],
  ["language", "She speaks two languages."],
  ["large", "The room is large."],
  ["large", "The room is large."],
  ["large", "The room is large."],
  ["last", "She was the last to leave."],
  ["last", "She was the last to leave."],
  ["last", "She was the last to leave."],
  ["late", "She arrived late."],
  ["late", "She arrived late."],
  ["late", "She was late to school."],
  ["later", "See you later!"],
  ["latter", "She preferred the latter option."],
  ["laugh", "They laughed at the joke."],
  ["laugh", "They laughed at the joke."],
  ["laugh", "They laughed at the joke."],
  ["launch", "They will launch the new product."],
  ["law", "He studies law."],
  ["law", "He studies law."],
  ["law", "He studies law."],
  ["lawn", "The lawn is green."],
  ["lawsuit", "They filed a lawsuit."],
  ["lawyer", "She is a lawyer."],
  ["lay", "She lay on the bed."],
  ["lay", "She lay on the bed."],
  ["lay", "She will lay the book on the table."],
  ["layer", "There is a layer of dust."],
  ["lazy", "He is a lazy person."],
  ["lead", "He will lead the team."],
  ["lead", "He will lead the team."],
  ["lead", "He will lead the team."],
  ["leader", "She is the leader."],
  ["leader", "She is the leader."],
  ["leaf", "A leaf fell from the tree."],
  ["league", "They play in a basketball league."],
  ["lean", "He will lean against the wall."],
  ["learn", "She loves to learn."],
  ["learn", "She loves to learn."],
  ["learn", "She loves to learn."],
  ["least", "She has the least homework."],
  ["least", "She has the least homework."],
  ["least", "She has the least homework."],
  ["leave", "She will leave soon."],
  ["leave", "She will leave soon."],
  ["leave", "She will leave soon."],
  ["lecture", "She gave a lecture."],
  ["led", "He led the team."],
  ["left", "She left early."],
  ["left", "She left her bag at home."],
  ["left", "She left her book at home."],
  ["leg", "Her leg hurts."],
  ["leg", "Her leg hurts."],
  ["leg", "Her leg hurts."],
  ["legal", "The action is legal."],
  ["legal", "The action is legal."],
  ["legend", "He is a legend in sports."],
  ["lemon", "She likes lemon in her tea."],
  ["lend", "Can you lend me a book?"],
  ["length", "The length of the table is five feet."],
  ["length", "The length of the table is six feet."],
  ["less", "He has less time."],
  ["less", "She has less candy than him."],
  ["less", "She has less time than him."],
  ["lesson", "She learned a lesson."],
  ["let", "Let us go to the park."],
  ["let", "Let us go to the park."],
  ["let", "Let's go to the park."],
  ["letter", "She wrote a letter."],
  ["letter", "She wrote a letter."],
  ["letter", "She wrote a letter."],
  ["level", "The water level is high."],
  ["level", "The water level is high."],
  ["liberty", "They cherish liberty."],
  ["library", "She studies at the library."],
  ["library", "She studies at the library."],
  ["license", "He has a driver's license."],
  ["lid", "She closed the lid."],
  ["lie", "Do not lie to your parents."],
  ["lie", "Don't lie to me."],
  ["lie", "He will lie down."],
  ["life", "She enjoys her life."],
  ["life", "She enjoys her life."],
  ["life", "She enjoys life."],
  ["lift", "He can lift heavy weights."],
  ["lift", "He will lift the box."],
  ["light", "The light is bright."],
  ["light", "The light is bright."],
  ["light", "The room is light."],
  ["like", "I like apples."],
  ["like", "I like apples."],
  ["like", "I like apples."],
  ["like", "I like to eat cookies with my superhero cape on."],
  ["likely", "It will likely rain tomorrow."],
  ["limb", "She hurt her limb."],
  ["limit", "There is a limit to speed."],
  ["line", "Draw a straight line."],
  ["line", "Draw a straight line."],
  ["line", "Draw a straight line."],
  ["link", "Click the link."],
  ["lion", "A lion is roaring."],
  ["lion", "A lion roars loudly."],
  ["liquid", "The liquid is hot."],
  ["list", "She made a list."],
  ["list", "She made a list."],
  ["list", "She made a list."],
  ["listen", "Listen to the music."],
  ["listen", "Listen to the music."],
  ["listen", "Listen to the music."],
  ["literature", "She studies literature."],
  ["little", "She has a little dog."],
  ["little", "She has a little dog."],
  ["little", "She has a little dog."],
  ["little", "She has a little dragon as a pet."],
  ["live", "I live in a castle made of candy."],
  ["live", "I live in the city."],
  ["live", "They live in a big city."],
  ["live", "They live in a big city."],
  ["load", "They will load the truck."],
  ["local", "They visited the local market."],
  ["local", "They visited the local market."],
  ["locate", "Can you locate the key?"],
  ["locate", "Can you locate the library?"],
  ["lock", "She turned the lock."],
  ["log", "He sat on a log."],
  ["log", "He sat on a log."],
  ["logic", "Use logic to solve the puzzle."],
  ["lone", "He felt lone."],
  ["lonely", "She feels lonely sometimes."],
  ["long", "The long road leads to the giant's house."],
  ["long", "The road is long."],
  ["long", "The road is long."],
  ["long", "The road is long."],
  ["look", "Look at the dancing cows!"],
  ["look", "Look at the stars."],
  ["look", "Look at the stars."],
  ["look", "Look at the stars."],
  ["loop", "She tied the loop."],
  ["loose", "The shirt is loose."],
  ["lose", "Do not lose the key."],
  ["lose", "Do not lose your keys."],
  ["loss", "The company reported a loss."],
  ["loss", "The loss was great."],
  ["lost", "She lost her keys."],
  ["lost", "She lost her way."],
  ["lot", "She has a lot of toys."],
  ["lot", "She has a lot of toys."],
  ["lot", "She has a lot of toys."],
  ["loud", "The music is loud."],
  ["loud", "The noise is loud."],
  ["love", "She loves her cat."],
  ["love", "She loves her family."],
  ["love", "She loves her family."],
  ["low", "The shelf is low."],
  ["low", "The shelf is low."],
  ["loyal", "He is loyal to his friends."],
  ["luck", "Wish her good luck."],
  ["lucky", "She is a lucky person."],
  ["luggage", "They packed their luggage."],
  ["lumber", "They needed lumber to build the house."],
  ["lump", "She found a lump."],
  ["lunch", "They had sandwiches for lunch."],
  ["lunch", "We had sandwiches for lunch."],
  ["luxury", "They live in luxury."],
  ["machine", "The machine is broken."],
  ["machine", "The machine is broken."],
  ["machine", "The machine is broken."],
  ["mad", "She was mad at him."],
  ["made", "He made a robot out of cardboard."],
  ["made", "She made a cake."],
  ["magazine", "She reads a fashion magazine."],
  ["magazine", "She reads a magazine."],
  ["magnet", "A magnet sticks to metal."],
  ["magnet", "The magnet sticks to the fridge."],
  ["maid", "The maid cleaned the room."],
  ["mail", "She sent a letter by mail."],
  ["mail", "She sent a letter by mail."],
  ["main", "The main door is open."],
  ["main", "The main gate is open."],
  ["main", "The main gate is open."],
  ["maintain", "They maintain the garden."],
  ["maintain", "They maintain the garden."],
  ["major", "This is a major issue."],
  ["major", "This is a major issue."],
  ["major", "This is a major problem."],
  ["majority", "The majority voted yes."],
  ["make", "Can you make a card?"],
  ["make", "Can you make a spaceship out of pillows?"],
  ["make", "She will make a cake."],
  ["make", "She will make a cake."],
  ["male", "The male bird is colorful."],
  ["mall", "They went shopping at the mall."],
  ["man", "The man is tall."],
  ["man", "The man is tall."],
  ["man", "The man is tall."],
  ["man", "The man with the big hat told funny stories."],
  ["manage", "She will manage the team."],
  ["manage", "She will manage the team."],
  ["management", "She is studying management."],
  ["manager", "He is the manager."],
  ["mandate", "There is a mandate to wear masks."],
  ["mango", "She ate a ripe mango."],
  ["manner", "He has good manners."],
  ["manual", "Read the manual first."],
  ["many", "She has many friends."],
  ["many", "She has many talking toys."],
  ["many", "She has many toys."],
  ["many", "There are many books."],
  ["map", "She looked at the map."],
  ["map", "She read the map."],
  ["marble", "The floor is made of marble."],
  ["march", "They will march in the parade."],
  ["mark", "Please mark the spot."],
  ["mark", "Please mark your answers."],
  ["market", "They went to the market."],
  ["market", "They went to the market."],
  ["market", "They went to the market."],
  ["marriage", "Their marriage is strong."],
  ["marry", "They decided to marry."],
  ["mask", "She wore a mask."],
  ["mass", "The mass of the object is 5 kg."],
  ["mass", "The mass of the object is 5 kg."],
  ["master", "He is a master at chess."],
  ["match", "The socks match."],
  ["material", "The dress is made of soft material."],
  ["material", "The dress is made of soft material."],
  ["math", "She enjoys math class."],
  ["matter", "What's the matter?"],
  ["matter", "What's the matter?"],
  ["maximum", "The maximum weight is 50 kg."],
  ["may", "May I borrow your magic wand?"],
  ["may", "May I come in?"],
  ["may", "May I come in?"],
  ["maybe", "Maybe we can go later."],
  ["maybe", "Maybe we can go later."],
  ["maybe", "Maybe we can go later."],
  ["mayor", "The mayor gave a speech."],
  ["me", "She gave me a gift."],
  ["me", "She gave me a gift."],
  ["me", "She gave me a gift."],
  ["me", "She gave me a necklace made of stars."],
  ["meal", "We had a big meal."],
  ["mean", "What does this funny word mean?"],
  ["mean", "What does this word mean?"],
  ["mean", "What does this word mean?"],
  ["mean", "What does this word mean?"],
  ["measure", "They measure the distance."],
  ["measure", "They measure the distance."],
  ["meat", "She likes meat."],
  ["meat", "She likes meat."],
  ["mechanic", "The mechanic fixed the car."],
  ["medal", "She won a gold medal."],
  ["media", "The news was reported by the media."],
  ["media", "The news was reported by the media."],
  ["medical", "She works in the medical field."],
  ["medical", "She works in the medical field."],
  ["medicine", "She took medicine for her cold."],
  ["medium", "She ordered a medium pizza."],
  ["meet", "Let's meet at the park."],
  ["meet", "Let's meet at the park."],
  ["meet", "Let's meet at the park."],
  ["meeting", "The meeting is at 10 AM."],
  ["melt", "The ice will melt in the sun."],
  ["melt", "The ice will melt in the sun."],
  ["member", "She is a member of the club."],
  ["member", "She is a member of the club."],
  ["member", "She is a member of the club."],
  ["memory", "She has a good memory."],
  ["memory", "She has a good memory."],
  ["memory", "She has a good memory."],
  ["men", "The men are working."],
  ["mention", "Don't forget to mention it."],
  ["mention", "Don't forget to mention it."],
  ["mention", "Don't forget to mention it."],
  ["menu", "The menu has many options."],
  ["merchant", "The merchant sells goods."],
  ["mercy", "They showed mercy."],
  ["message", "She left a message."],
  ["message", "She left a message."],
  ["metal", "The gate is made of metal."],
  ["meter", "The pool is 50 meters long."],
  ["method", "She tried a new method."],
  ["method", "She tried a new method."],
  ["middle", "The ball is in the middle."],
  ["middle", "The ball is in the middle."],
  ["middle", "The ball is in the middle."],
  ["might", "It might rain frogs today."],
  ["might", "It might rain today."],
  ["might", "It might rain today."],
  ["might", "It might rain today."],
  ["mile", "They walked a mile."],
  ["mile", "They walked a mile."],
  ["military", "He served in the military."],
  ["milk", "She drinks milk."],
  ["milk", "She drinks milk."],
  ["million", "There are a million stars."],
  ["million", "There are a million stars."],
  ["million", "There are a million stars."],
  ["mind", "She changed her mind."],
  ["mind", "She changed her mind."],
  ["mind", "She changed her mind."],
  ["mine", "The book is mine."],
  ["mine", "The book is mine."],
  ["minimum", "The minimum age is 18."],
  ["minute", "Wait a minute."],
  ["minute", "Wait a minute."],
  ["minute", "Wait a minute."],
  ["miracle", "It was a miracle."],
  ["mirror", "She looked in the mirror."],
  ["miss", "She missed the bus."],
  ["miss", "She missed the bus."],
  ["miss", "She missed the bus."],
  ["mission", "The mission was successful."],
  ["mission", "The mission was successful."],
  ["mistake", "She made a mistake."],
  ["mix", "She likes to mix colors."],
  ["model", "She built a model car."],
  ["model", "She built a model car."],
  ["modern", "They live in a modern house."],
  ["modern", "They live in a modern house."],
  ["modern", "They live in a modern house."],
  ["molecule", "Water is a molecule."],
  ["mom", "My mom can bake a cake shaped like a castle."],
  ["moment", "Just a moment, please."],
  ["moment", "Just a moment, please."],
  ["moment", "Just a moment, please."],
  ["money", "She saved her money."],
  ["money", "She saved her money."],
  ["money", "She saved her money."],
  ["month", "January is a cold month."],
  ["month", "January is a cold month."],
  ["month", "January is a cold month."],
  ["moon", "The moon is bright."],
  ["moon", "The moon is bright."],
  ["more", "I want more ice cream."],
  ["more", "I want more ice cream."],
  ["more", "I want more ice cream."],
  ["more", "I want more jellybeans, please."],
  ["morning", "Good morning!"],
  ["morning", "Good morning!"],
  ["morning", "Good morning!"],
  ["most", "She is the most talented dragon trainer."],
  ["most", "She is the most talented."],
  ["most", "She is the most talented."],
  ["most", "She is the most talented."],
  ["mother", "Her mother is kind."],
  ["mother", "Her mother is kind."],
  ["mother", "Her mother is kind."],
  ["mother", "Her mother knows how to speak to dolphins."],
  ["motion", "The motion was smooth."],
  ["motor", "The motor is running."],
  ["mountain", "They climbed the mountain."],
  ["mountain", "They climbed the mountain."],
  ["mouse", "A mouse is small."],
  ["mouse", "A mouse is small."],
  ["mouth", "She opened her mouth."],
  ["mouth", "She opened her mouth."],
  ["mouth", "She opened her mouth."],
  ["move", "Please move the chair."],
  ["move", "Please move the chair."],
  ["move", "Please move the chair."],
  ["movement", "The dance had a lot of movement."],
  ["movie", "They watched a movie."],
  ["movie", "They watched a movie."],
  ["much", "I love you very much, even more than chocolate."],
  ["much", "I love you very much."],
  ["much", "I love you very much."],
  ["much", "I love you very much."],
  ["mud", "The shoes are covered in mud."],
  ["multiply", "Multiply two by three."],
  ["multiply", "Multiply two by three."],
  ["murder", "The movie is about a murder."],
  ["museum", "They visited the museum."],
  ["music", "She loves music."],
  ["music", "She loves music."],
  ["music", "She loves music."],
  ["must", "You must do your homework."],
  ["must", "You must do your homework."],
  ["must", "You must do your homework."],
  ["must", "You must wear a funny hat to enter."],
  ["mutual", "They have mutual respect."],
  ["my", "This is my best joke book."],
  ["my", "This is my book."],
  ["my", "This is my book."],
  ["my", "This is my book."],
  ["myself", "I did it myself."],
  ["myself", "I did it myself."],
  ["mystery", "The book is a mystery."],
  ["nail", "She painted her nail."],
  ["name", "My name is Alice."],
  ["name", "My name is Alice."],
  ["name", "My name is Alice."],
  ["name", "My name is Captain Giggles."],
  ["narrow", "The path is narrow."],
  ["nation", "Our nation is strong."],
  ["nation", "Our nation is strong."],
  ["nation", "Our nation is strong."],
  ["national", "The national flag is beautiful."],
  ["natural", "The view is natural."],
  ["natural", "The view is natural."],
  ["nature", "She loves nature."],
  ["nature", "She loves nature."],
  ["near", "The park is near my house."],
  ["near", "The park is near my house."],
  ["near", "The park is near my house."],
  ["nearly", "It is nearly finished."],
  ["nearly", "It is nearly finished."],
  ["necessary", "It is necessary to study."],
  ["necessary", "It is necessary to study."],
  ["necessary", "It is necessary to study."],
  ["neck", "She has a sore neck."],
  ["neck", "She has a sore neck."],
  ["need", "I need a pen."],
  ["need", "I need a pen."],
  ["need", "I need a pen."],
  ["negative", "She received negative feedback."],
  ["neighbor", "Her neighbor is kind."],
  ["neighborhood", "The neighborhood is quiet."],
  ["neither", "Neither option is good."],
  ["neither", "Neither option is good."],
  ["nervous", "She felt nervous before the test."],
  ["nest", "The bird is building a nest."],
  ["net", "The fisherman cast his net."],
  ["network", "They set up a network."],
  ["network", "They set up a network."],
  ["never", "I never lie."],
  ["never", "I never lie."],
  ["never", "I never lie."],
  ["new", "I have a new bike."],
  ["new", "I have a new bike."],
  ["new", "I have a new bike."],
  ["new", "I have a new pet robot."],
  ["news", "They watched the news."],
  ["news", "They watched the news."],
  ["next", "You are next to ride the flying carpet."],
  ["next", "You are next."],
  ["next", "You are next."],
  ["next", "You are next."],
  ["nice", "She is a nice person."],
  ["nice", "She is a nice person."],
  ["night", "Good night!"],
  ["night", "Good night!"],
  ["night", "Good night!"],
  ["night", "Good night! Don't let the bedbugs dance!"],
  ["nine", "She has nine marbles."],
  ["nine", "She has nine marbles."],
  ["no", "No, thank you."],
  ["no", "No, thank you."],
  ["no", "No, thank you."],
  ["nobody", "Nobody knows the answer."],
  ["noise", "The noise is loud."],
  ["noise", "The noise is loud."],
  ["nominate", "They will nominate her for president."],
  ["none", "None of them are here."],
  ["none", "None of them are here."],
  ["noon", "We have lunch at noon."],
  ["noon", "We have lunch at noon."],
  ["nor", "She neither called nor visited."],
  ["nor", "She neither called nor visited."],
  ["north", "They traveled north."],
  ["north", "They traveled north."],
  ["north", "They traveled north."],
  ["nose", "She touched her nose."],
  ["nose", "She touched her nose."],
  ["not", "I do not like green eggs and ham."],
  ["not", "She does not like carrots."],
  ["not", "She does not like carrots."],
  ["note", "He left a note on the table."],
  ["note", "He left a note on the table."],
  ["note", "He left a note on the table."],
  ["nothing", "Nothing is impossible."],
  ["nothing", "Nothing is impossible."],
  ["nothing", "Nothing is impossible."],
  ["notice", "Did you notice the sign?"],
  ["notice", "Did you notice the sign?"],
  ["notice", "Did you notice the sign?"],
  ["noun", "A noun is a person, place, or thing."],
  ["noun", "A noun is a person, place, or thing."],
  ["now", "We can play now with the talking teddy bear."],
  ["now", "We can play now."],
  ["now", "We can play now."],
  ["now", "We can play now."],
  ["number", "What is your favorite number of dancing cows?"],
  ["number", "What is your favorite number?"],
  ["number", "What is your favorite number?"],
  ["number", "What is your favorite number?"],
  ["nurse", "The nurse is kind."],
  ["nut", "She cracked the nut."],
  ["obey", "The dog will obey commands."],
  ["object", "He pointed to the object."],
  ["object", "He pointed to the object."],
  ["observe", "They observe the stars."],
  ["obtain", "She needs to obtain permission."],
  ["obvious", "It is obvious that she is tired."],
  ["occasion", "They met on a special occasion."],
  ["occur", "It will occur naturally."],
  ["occur", "It will occur naturally."],
  ["ocean", "The ocean is vast."],
  ["ocean", "The ocean is vast."],
  ["odd", "The number three is odd."],
  ["of", "This is a cup of magical tea."],
  ["of", "This is a cup of tea."],
  ["of", "This is a cup of tea."],
  ["of", "This is a cup of tea."],
  ["off", "Turn off the light."],
  ["off", "Turn off the light."],
  ["off", "Turn off the light."],
  ["off", "Turn off the lights to see the glow-in-the-dark unicorn."],
  ["offer", "She made an offer."],
  ["offer", "She made an offer."],
  ["offer", "She made an offer."],
  ["office", "He works in an office."],
  ["office", "He works in an office."],
  ["office", "He works in an office."],
  ["officer", "The officer was helpful."],
  ["often", "I often read books."],
  ["often", "I often read books."],
  ["often", "I often read books."],
  ["oh", "Oh, I forgot my keys."],
  ["oh", "Oh, I forgot my keys."],
  ["oh", "Oh, I forgot my keys."],
  ["oil", "The oil is hot."],
  ["oil", "The oil is hot."],
  ["oil", "The oil is hot."],
  ["okay", "Okay, I understand."],
  ["old", "This book is old."],
  ["old", "This book is old."],
  ["old", "This book is old."],
  ["old", "This old book tells stories about invisible friends."],
  ["olive", "She likes olives."],
  ["on", "The book is on the table."],
  ["on", "The book is on the table."],
  ["on", "The book is on the table."],
  ["on", "The book is on the wizard's shelf."],
  ["once", "Once upon a time."],
  ["once", "Once upon a time."],
  ["once", "Once upon a time."],
  ["one", "She has one cat."],
  ["one", "She has one cat."],
  ["one", "She has one cat."],
  ["one", "She has one rainbow unicorn."],
  ["only", "This is the only cookie that sings."],
  ["only", "This is the only one."],
  ["only", "This is the only one."],
  ["only", "This is the only pen I have."],
  ["onto", "Climb onto the platform."],
  ["onto", "Climb onto the platform."],
  ["open", "Please open the door."],
  ["open", "Please open the door."],
  ["open", "Please open the door."],
  ["operate", "He can operate the machine."],
  ["operate", "He can operate the machine."],
  ["operation", "The operation was successful."],
  ["opinion", "She has a strong opinion."],
  ["opinion", "She has a strong opinion."],
  ["opportunity", "She took the opportunity."],
  ["opportunity", "She took the opportunity."],
  ["oppose", "They oppose the plan."],
  ["opposite", "The opposite of hot is cold."],
  ["opposite", "The opposite of hot is cold."],
  ["option", "You have the option to choose."],
  ["or", "Would you like tea or coffee?"],
  ["or", "Would you like tea or coffee?"],
  ["or", "Would you like tea or coffee?"],
  ["or", "Would you like to dance or sing with the aliens?"],
  ["orange", "She likes orange juice."],
  ["orbit", "The moon orbits the earth."],
  ["order", "Please take my order."],
  ["order", "Please take my order."],
  ["order", "Please take my order."],
  ["ordinary", "It was an ordinary day."],
  ["organ", "The heart is an organ."],
  ["organization", "She works for a large organization."],
  ["organize", "Let's organize the event."],
  ["organize", "Let's organize the room."],
  ["origin", "The origin of the story is unknown."],
  ["original", "This is the original painting."],
  ["other", "The other kids are playing."],
  ["other", "The other kids are playing."],
  ["other", "The other robots are having a tea party."],
  ["other", "The other students are waiting."],
  ["others", "The others are coming."],
  ["ought", "You ought to listen."],
  ["our", "This is our car."],
  ["our", "This is our home."],
  ["our", "This is our home."],
  ["our", "This is our magical garden."],
  ["ours", "This book is ours."],
  ["ourselves", "We did it ourselves."],
  ["out", "Let's go out and look for treasure."],
  ["out", "Let's go out and play."],
  ["out", "Let's go out and play."],
  ["out", "Let's go out and play."],
  ["outcome", "The outcome was positive."],
  ["outside", "The cat is outside."],
  ["outside", "The kids are playing outside."],
  ["outside", "The kids are playing outside."],
  ["oven", "She baked cookies in the oven."],
  ["over", "The balloon flew over the giant pizza."],
  ["over", "The plane flew over the city."],
  ["over", "The show is over."],
  ["over", "The show is over."],
  ["overcome", "She will overcome the obstacle."],
  ["owe", "I owe you money."],
  ["own", "She has her own castle in the sky."],
  ["own", "She has her own room."],
  ["own", "She has her own room."],
  ["own", "She has her own room."],
  ["owner", "He is the owner."],
  ["owner", "She is the owner of the car."],
  ["oxygen", "We need oxygen to breathe."],
  ["pack", "She will pack her suitcase."],
  ["page", "Turn the page."],
  ["page", "Turn the page."],
  ["page", "Turn the page."],
  ["pain", "She felt a sharp pain."],
  ["paint", "She likes to paint."],
  ["paint", "She likes to paint."],
  ["painting", "The painting is beautiful."],
  ["pair", "I bought a new pair of shoes."],
  ["pair", "I bought a new pair of shoes."],
  ["palace", "They visited the palace."],
  ["pale", "Her face looked pale."],
  ["pan", "She cooked in a pan."],
  ["panel", "They discussed the topic on a panel."],
  ["panic", "Do not panic."],
  ["paper", "Write on the paper."],
  ["paper", "Write on the paper."],
  ["paper", "Write on the paper."],
  ["paragraph", "Write a paragraph."],
  ["parallel", "The lines are parallel."],
  ["parent", "Her parent is kind."],
  ["parent", "Her parent is kind."],
  ["parent", "Her parent is kind."],
  ["park", "They went to the park."],
  ["park", "They went to the park."],
  ["park", "They went to the park."],
  ["part", "This is part of the puzzle."],
  ["part", "This is part of the puzzle."],
  ["part", "This is part of the puzzle."],
  ["part", "This is part of the robot's dance routine."],
  ["participant", "She is a participant in the contest."],
  ["particular", "I have no particular preference."],
  ["particular", "I have no particular preference."],
  ["particular", "This is a particular case."],
  ["particularly", "She particularly likes ice cream."],
  ["partner", "She is my partner."],
  ["partner", "She is my partner."],
  ["party", "She had a birthday party."],
  ["party", "She had a birthday party."],
  ["party", "She had a birthday party."],
  ["pass", "Pass the salt, please."],
  ["pass", "Pass the salt, please."],
  ["pass", "Pass the salt, please."],
  ["passage", "Read the passage."],
  ["past", "In the past, he lived here."],
  ["past", "In the past, he lived here."],
  ["past", "In the past, he lived here."],
  ["path", "They walked along the path."],
  ["path", "They walked along the path."],
  ["patient", "The doctor is with a patient."],
  ["pattern", "The dress has a nice pattern."],
  ["pattern", "The dress has a nice pattern."],
  ["pattern", "The dress has a nice pattern."],
  ["pause", "She took a pause."],
  ["pay", "Pay the bill, please."],
  ["pay", "Pay the bill, please."],
  ["pay", "Pay the bill, please."],
  ["peace", "They live in peace."],
  ["peace", "They live in peace."],
  ["peach", "She ate a peach."],
  ["pen", "She wrote with a pen."],
  ["pencil", "She sharpened her pencil."],
  ["people", "Many people are here."],
  ["people", "Many people are here."],
  ["people", "Many people are here."],
  ["people", "Many people like to wear silly hats."],
  ["pepper", "She likes black pepper."],
  ["perfect", "The day was perfect."],
  ["perform", "She will perform a song."],
  ["performance", "The performance was excellent."],
  ["perhaps", "Perhaps we can go later."],
  ["perhaps", "Perhaps we can go later."],
  ["perhaps", "Perhaps we can go later."],
  ["period", "He has a free period."],
  ["period", "He has a free period."],
  ["period", "He has a free period."],
  ["permit", "She got a permit."],
  ["person", "She is a nice person."],
  ["person", "She is a nice person."],
  ["person", "She is a nice person."],
  ["personal", "She shared a personal story."],
  ["personal", "She shared a personal story."],
  ["pet", "She has a pet cat."],
  ["phone", "She called him on the phone."],
  ["phone", "She called him on the phone."],
  ["photo", "He took a photo."],
  ["phrase", "He used a common phrase."],
  ["phrase", "He used a common phrase."],
  ["physical", "Physical exercise is important."],
  ["physical", "Physical exercise is important."],
  ["piano", "She plays the piano."],
  ["pick", "Pick a color."],
  ["pick", "Pick a color."],
  ["pick", "Pick a color."],
  ["picture", "She drew a picture of a dancing cactus."],
  ["picture", "She drew a picture."],
  ["picture", "She drew a picture."],
  ["picture", "She drew a picture."],
  ["piece", "She ate a piece of cake."],
  ["piece", "She ate a piece of cake."],
  ["piece", "She ate a piece of cake."],
  ["pig", "A pig is in the pen."],
  ["pig", "A pig is in the pen."],
  ["pile", "There is a pile of books."],
  ["pilot", "The pilot flew the plane."],
  ["pink", "She wore a pink dress."],
  ["pipe", "The pipe is leaking."],
  ["place", "This place is beautiful."],
  ["place", "This place is beautiful."],
  ["place", "This place is full of talking animals."],
  ["plain", "The plain dress is simple."],
  ["plan", "We need a plan."],
  ["plan", "We need a plan."],
  ["plane", "The plane took off."],
  ["planet", "Earth is a planet."],
  ["planet", "Earth is a planet."],
  ["plant", "She watered the plant."],
  ["plant", "She watered the plant."],
  ["plant", "She watered the plant."],
  ["plastic", "The toy is made of plastic."],
  ["plate", "She broke a plate."],
  ["play", "The kids play outside."],
  ["play", "The kids play outside."],
  ["play", "The kids play outside."],
  ["play", "The kids play with their pet dragons."],
  ["player", "He is a good player."],
  ["please", "Please pass the magic beans."],
  ["please", "Please pass the salt."],
  ["please", "Please pass the salt."],
  ["plug", "Plug in the charger."],
  ["plural", "Cats is the plural of cat."],
  ["plural", "Cats is the plural of cat."],
  ["plus", "Two plus two is four."],
  ["plus", "Two plus two is four."],
  ["PM", "The meeting is at 3 PM."],
  ["pocket", "She put the key in her pocket."],
  ["poem", "She wrote a poem."],
  ["poem", "She wrote a poem."],
  ["poet", "He is a famous poet."],
  ["point", "What's the point?"],
  ["point", "What's the point?"],
  ["point", "What's the point?"],
  ["poison", "The berries are poison."],
  ["pole", "He climbed the pole."],
  ["police", "The police helped."],
  ["police", "The police helped."],
  ["policy", "The policy is new."],
  ["polish", "She polished the silver."],
  ["polite", "Be polite to others."],
  ["political", "They discussed political issues."],
  ["politics", "They discussed politics."],
  ["politics", "They discussed politics."],
  ["pond", "Ducks swim in the pond."],
  ["pool", "They swam in the pool."],
  ["poor", "The poor dog is sick."],
  ["poor", "The poor dog is sick."],
  ["poor", "The poor dog is sick."],
  ["popular", "The singer is popular."],
  ["population", "The population is growing."],
  ["population", "The population is growing."],
  ["port", "They arrived at the port."],
  ["position", "He changed his position."],
  ["position", "He changed his position."],
  ["position", "He changed his position."],
  ["positive", "She received positive feedback."],
  ["positive", "She received positive feedback."],
  ["possible", "Anything is possible."],
  ["possible", "Anything is possible."],
  ["possible", "Anything is possible."],
  ["post", "She mailed the post."],
  ["post", "She mailed the post."],
  ["pot", "She cooked in a pot."],
  ["potato", "She peeled a potato."],
  ["pound", "The bag weighs a pound."],
  ["pour", "Pour the water slowly."],
  ["powder", "She added powder to the mix."],
  ["power", "The car has a lot of power."],
  ["power", "The car has a lot of power."],
  ["power", "The power is out."],
  ["practice", "She needs more practice."],
  ["practice", "She needs more practice."],
  ["practice", "She needs more practice."],
  ["prepare", "Prepare the table for dinner."],
  ["prepare", "Prepare the table."],
  ["prepare", "Prepare the table."],
  ["present", "She gave a present."],
  ["present", "She gave a present."],
  ["present", "She gave him a present."],
  ["preserve", "They preserve old buildings."],
  ["president", "The president gave a speech."],
  ["press", "Press the button to start."],
  ["pressure", "There is pressure to succeed."],
  ["pretend", "Children like to pretend."],
  ["pretty", "The flowers are pretty."],
  ["pretty", "The flowers are pretty."],
  ["pretty", "The flowers are pretty."],
  ["prevent", "Prevent the fire from spreading."],
  ["prevent", "They want to prevent accidents."],
  ["price", "The price is high."],
  ["price", "The price is high."],
  ["pride", "She takes pride in her work."],
  ["primary", "Her primary job is teaching."],
  ["prime", "Prime numbers are special."],
  ["print", "Print your name here."],
  ["print", "Print your name here."],
  ["prior", "He had no prior experience."],
  ["priority", "Her priority is her family."],
  ["prison", "The thief is in prison."],
  ["private", "This is private property."],
  ["private", "This is private property."],
  ["prize", "She won a prize."],
  ["probable", "It is probable that it will rain."],
  ["probably", "It will probably rain."],
  ["probably", "It will probably rain."],
  ["problem", "There is a problem."],
  ["problem", "There is a problem."],
  ["problem", "We have a problem."],
  ["procedure", "Follow the procedure."],
  ["proceed", "They will proceed with caution."],
  ["process", "The process is simple."],
  ["process", "The process is simple."],
  ["process", "The process is simple."],
  ["produce", "Farms produce food."],
  ["produce", "Farms produce food."],
  ["produce", "Farms produce food."],
  ["product", "This is a new product."],
  ["product", "This is a new product."],
  ["product", "This is a new product."],
  ["production", "Production has increased."],
  ["profession", "She loves her profession."],
  ["professional", "He is a professional player."],
  ["professional", "He is a professional player."],
  ["professor", "The professor gave a lecture."],
  ["profit", "The profit was high."],
  ["program", "She created a program."],
  ["program", "She created a program."],
  ["progress", "They made progress."],
  ["project", "This is a new project."],
  ["project", "This is a new project."],
  ["promise", "She made a promise."],
  ["promote", "They promote healthy eating."],
  ["prompt", "She gave a prompt response."],
  ["proof", "They need proof of identity."],
  ["proper", "This is the proper way."],
  ["property", "This is private property."],
  ["property", "This is private property."],
  ["property", "This is private property."],
  ["proportion", "The proportion is correct."],
  ["proposal", "They considered the proposal."],
  ["propose", "He will propose an idea."],
  ["protect", "Protect your skin from the sun."],
  ["protect", "Protect your skin from the sun."],
  ["protect", "Protect your skin from the sun."],
  ["protein", "Protein is important for health."],
  ["protest", "They will protest the decision."],
  ["proud", "She is proud of her work."],
  ["prove", "He can prove it."],
  ["prove", "He can prove it."],
  ["prove", "He can prove it."],
  ["provide", "They provide food and water."],
  ["provide", "They provide food and water."],
  ["provide", "They provide food and water."],
  ["public", "This is a public park."],
  ["public", "This is a public park."],
  ["public", "This is a public park."],
  ["publish", "They will publish a book."],
  ["pull", "Please pull the door open."],
  ["pull", "Please pull the door open."],
  ["pull", "Pull the door to open it."],
  ["pulp", "The orange pulp is tasty."],
  ["pulse", "She has a strong pulse."],
  ["pump", "The pump is broken."],
  ["punch", "He gave a punch."],
  ["purchase", "She made a purchase."],
  ["pure", "The water is pure."],
  ["purple", "She wore a purple dress."],
  ["purpose", "The purpose is clear."],
  ["purpose", "The purpose is clear."],
  ["purse", "She lost her purse."],
  ["push", "Push the button to start."],
  ["push", "Push the button."],
  ["push", "Push the button."],
  ["put", "Put the book here."],
  ["put", "Put the book here."],
  ["put", "Put the book here."],
  ["put", "Put the hat on the dancing bear."],
  ["puzzle", "The puzzle is difficult."],
  ["qualify", "She will qualify for the race."],
  ["quality", "The quality is high."],
  ["quality", "The quality is high."],
  ["quantity", "The quantity is large."],
  ["quarter", "A quarter of the cake is gone."],
  ["queen", "The queen wore a crown."],
  ["question", "Do you have a question?"],
  ["question", "Do you have a question?"],
  ["question", "She asked a question."],
  ["quick", "He is quick to learn."],
  ["quick", "He is quick to learn."],
  ["quick", "The car is quick."],
  ["quickly", "She quickly finished her work."],
  ["quiet", "Please be quiet."],
  ["quiet", "Please be quiet."],
  ["quit", "He decided to quit his job."],
  ["quite", "It is quite cold today."],
  ["quite", "It is quite cold today."],
  ["quite", "It is quite hot today."],
  ["quote", "She included a quote."],
  ["rabbit", "A rabbit is hopping."],
  ["race", "They had a race."],
  ["race", "They had a race."],
  ["radio", "She listened to the radio."],
  ["radio", "She listened to the radio."],
  ["radio", "She listened to the radio."],
  ["rail", "The train runs on a rail."],
  ["rain", "It will rain tomorrow."],
  ["rain", "It will rain tomorrow."],
  ["raise", "Please raise your hand."],
  ["raise", "She wants to raise her hand."],
  ["ran", "He ran fast."],
  ["range", "The range is wide."],
  ["range", "The range is wide."],
  ["rapid", "The river is rapid."],
  ["rare", "The bird is rare."],
  ["rate", "The rate is high."],
  ["rate", "The rate is high."],
  ["rather", "I would rather stay home."],
  ["rather", "I would rather stay home."],
  ["raw", "The meat is raw."],
  ["reach", "He tried to reach the shelf."],
  ["reach", "He tried to reach the top shelf."],
  ["reach", "She tried to reach the top shelf."],
  ["react", "She will react quickly."],
  ["read", "She likes to read books."],
  ["read", "She likes to read books."],
  ["read", "She likes to read books."],
  ["read", "She likes to read stories about flying monkeys."],
  ["ready", "Are you ready?"],
  ["ready", "Are you ready?"],
  ["ready", "Are you ready?"],
  ["real", "The story is real."],
  ["real", "The story is real."],
  ["real", "The story is real."],
  ["reality", "The reality is different."],
  ["realize", "She will realize the truth."],
  ["realize", "She will realize the truth."],
  ["really", "It is really cold today."],
  ["really", "It is really cold today."],
  ["really", "It is really cold today."],
  ["reason", "Give me a reason."],
  ["reason", "Give me a reason."],
  ["reason", "Give me a reason."],
  ["recall", "She can recall facts easily."],
  ["receive", "She will receive a gift."],
  ["receive", "She will receive a gift."],
  ["receive", "She will receive a gift."],
  ["recent", "The news is recent."],
  ["recent", "The news is recent."],
  ["recently", "She recently moved here."],
  ["recipe", "She follows a recipe."],
  ["recognize", "She did not recognize him."],
  ["recognize", "She did not recognize him."],
  ["recommend", "They recommend this restaurant."],
  ["record", "Keep a record of your progress."],
  ["record", "Keep a record of your progress."],
  ["record", "Keep a record of your progress."],
  ["recover", "She will recover soon."],
  ["red", "The apple is red."],
  ["red", "The apple is red."],
  ["red", "The red balloon floated to the moon."],
  ["reduce", "They will reduce the price."],
  ["reduce", "They will reduce the price."],
  ["refer", "Please refer to the guide."],
  ["reflect", "The mirror reflects light."],
  ["reflect", "The mirror reflects light."],
  ["reform", "They want to reform the system."],
  ["refuse", "She will refuse the offer."],
  ["regard", "He holds her in high regard."],
  ["region", "This region is dry."],
  ["region", "This region is dry."],
  ["region", "This region is dry."],
  ["regret", "She feels regret."],
  ["regular", "He is a regular customer."],
  ["reject", "They will reject the application."],
  ["relate", "She can relate to the story."],
  ["relate", "She can relate to the story."],
  ["relationship", "They have a good relationship."],
  ["release", "They will release the movie."],
  ["relief", "She felt relief."],
  ["religious", "She is very religious."],
  ["rely", "She can rely on him."],
  ["remain", "Please remain seated."],
  ["remain", "Please remain seated."],
  ["remark", "She made a remark."],
  ["remember", "Do you remember me?"],
  ["remember", "Do you remember me?"],
  ["remember", "Do you remember me?"],
  ["remind", "Please remind me to call."],
  ["remove", "Please remove your shoes."],
  ["remove", "Remove the sticker."],
  ["render", "She will render help."],
  ["rent", "They rent an apartment."],
  ["repair", "They will repair the car."],
  ["repeat", "Please repeat that."],
  ["repeat", "Please repeat the question."],
  ["replace", "She will replace the battery."],
  ["reply", "She will reply soon."],
  ["report", "Write a report."],
  ["report", "Write a report."],
  ["report", "Write a report."],
  ["represent", "She will represent the class."],
  ["represent", "She will represent the team."],
  ["represent", "She will represent the team."],
  ["reproduce", "They will reproduce the sound."],
  ["require", "This job requires experience."],
  ["require", "This job requires experience."],
  ["require", "This job requires experience."],
  ["rescue", "The firefighters rescued the cat."],
  ["research", "They conduct research."],
  ["research", "They conduct research."],
  ["reserve", "They will reserve a table."],
  ["reside", "He resides in New York."],
  ["resist", "She could not resist the cake."],
  ["resolve", "They will resolve the conflict."],
  ["resort", "They stayed at a resort."],
  ["resource", "Water is a valuable resource."],
  ["resource", "Water is a valuable resource."],
  ["respect", "She has respect for him."],
  ["respond", "Please respond quickly."],
  ["respond", "Please respond quickly."],
  ["response", "She gave a good response."],
  ["responsibility", "She has a lot of responsibility."],
  ["rest", "Take a rest."],
  ["rest", "Take a rest."],
  ["rest", "Take a rest."],
  ["restaurant", "They eat at a restaurant."],
  ["result", "The result is clear."],
  ["result", "The result is clear."],
  ["result", "The result is clear."],
  ["retain", "They retain the right."],
  ["retire", "She will retire next year."],
  ["return", "She will return the book."],
  ["return", "She will return the book."],
  ["reveal", "The test will reveal the truth."],
  ["reveal", "The test will reveal the truth."],
  ["revenue", "The revenue increased."],
  ["review", "She will review the book."],
  ["reward", "She got a reward."],
  ["rhythm", "She has a good sense of rhythm."],
  ["rice", "She cooked rice."],
  ["rich", "He is a rich man."],
  ["rich", "He is a rich man."],
  ["rich", "He is a rich man."],
  ["ride", "She likes to ride her bike."],
  ["ride", "They like to ride bikes."],
  ["right", "Turn right at the corner."],
  ["right", "Turn right at the corner."],
  ["right", "Turn right at the corner."],
  ["right", "Turn right at the laughing tree."],
  ["ring", "He gave her a ring."],
  ["ring", "She wore a ring."],
  ["rise", "The sun will rise soon."],
  ["rise", "The sun will rise soon."],
  ["rise", "The sun will rise soon."],
  ["risk", "There is a risk involved."],
  ["risk", "There is a risk involved."],
  ["river", "The river is wide."],
  ["river", "They crossed the river."],
  ["road", "The road is long."],
  ["road", "The road is long."],
  ["road", "They walked along the road."],
  ["roar", "The lion gave a roar."],
  ["rock", "She found a rock."],
  ["rock", "She found a rock."],
  ["rock", "She found a smooth rock."],
  ["role", "He played a role in the play."],
  ["role", "He played a role in the play."],
  ["roll", "Roll the ball to me."],
  ["roll", "Roll the ball to me."],
  ["roof", "The roof is leaking."],
  ["room", "The room is clean."],
  ["room", "The room is clean."],
  ["room", "The room is clean."],
  ["root", "The root is strong."],
  ["root", "The tree's root is strong."],
  ["rope", "He climbed the rope."],
  ["rope", "They climbed the rope."],
  ["rose", "A rose is a beautiful flower."],
  ["rose", "She received a rose."],
  ["rough", "The road is rough."],
  ["round", "The ball is round."],
  ["round", "The ball is round."],
  ["route", "This is the best route."],
  ["row", "They sat in a row."],
  ["row", "They sat in a row."],
  ["royal", "She is of royal blood."],
  ["rub", "She will rub the stain."],
  ["rubber", "The ball is made of rubber."],
  ["rude", "He was rude to her."],
  ["ruin", "The building is in ruins."],
  ["rule", "Follow the rule."],
  ["rule", "Follow the rule."],
  ["rule", "Follow the rule."],
  ["run", "He likes to run."],
  ["run", "He likes to run."],
  ["run", "He likes to run."],
  ["rush", "He is in a rush."],
  ["sad", "She is feeling sad."],
  ["safe", "The children are safe."],
  ["safe", "The children are safe."],
  ["safe", "The children are safe."],
  ["said", "She said hello to the talking cat."],
  ["said", "She said hello."],
  ["sail", "They will sail tomorrow."],
  ["salad", "She made a salad."],
  ["salary", "She earns a good salary."],
  ["sale", "The sale starts tomorrow."],
  ["salt", "Add a pinch of salt."],
  ["same", "They wore the same dress."],
  ["same", "They wore the same dress."],
  ["same", "We have the same invisible friend."],
  ["same", "We have the same shoes."],
  ["sand", "The sand is warm."],
  ["sand", "The sand is warm."],
  ["sat", "He sat on the bench."],
  ["sat", "He sat on the chair."],
  ["satisfy", "She will satisfy the requirements."],
  ["sauce", "She poured sauce on the pasta."],
  ["save", "Save your work frequently."],
  ["save", "Save your work."],
  ["save", "Save your work."],
  ["saw", "I saw a movie."],
  ["saw", "I saw a unicorn eating ice cream."],
  ["saw", "She saw a bird."],
  ["say", "What did you say to the giggling ghost?"],
  ["say", "What did you say?"],
  ["say", "What did you say?"],
  ["say", "What did you say?"],
  ["scale", "The fish has scales."],
  ["scare", "The loud noise scared him."],
  ["scatter", "The wind will scatter the leaves."],
  ["scene", "The final scene was emotional."],
  ["scene", "The final scene was emotional."],
  ["schedule", "She checked her schedule."],
  ["scheme", "They devised a scheme."],
  ["school", "I go to school."],
  ["school", "She goes to school."],
  ["school", "She goes to school."],
  ["science", "Science is fascinating."],
  ["science", "She loves science."],
  ["science", "She loves science."],
  ["scissors", "She used scissors to cut."],
  ["score", "He scored a goal."],
  ["score", "He scored a goal."],
  ["score", "He scored a goal."],
  ["scrape", "He scraped the car door."],
  ["scratch", "The cat scratched the couch."],
  ["scream", "She let out a scream."],
  ["screen", "The TV screen is big."],
  ["script", "The script is well-written."],
  ["sea", "They sailed on the sea."],
  ["sea", "They sailed on the sea."],
  ["sea", "They swam in the sea."],
  ["search", "Search for the answer."],
  ["search", "She will search for clues."],
  ["season", "Autumn is my favorite season."],
  ["season", "Spring is her favorite season."],
  ["season", "Spring is her favorite season."],
  ["seat", "Please take a seat."],
  ["seat", "Please take a seat."],
  ["seat", "Take a seat."],
  ["second", "She finished in second place."],
  ["second", "She finished in second place."],
  ["second", "She finished in second place."],
  ["secret", "She told me a secret."],
  ["section", "Read the first section."],
  ["section", "This section is important."],
  ["section", "This section is important."],
  ["secure", "The lock is secure."],
  ["security", "They have tight security."],
  ["see", "I see a bird."],
  ["see", "I see a bird."],
  ["see", "I see a bird."],
  ["see", "I see a dragon in the sky."],
  ["seed", "Plant the seed."],
  ["seed", "Plant the seed."],
  ["seek", "He will seek help."],
  ["seek", "He will seek help."],
  ["seem", "You seem happy."],
  ["seem", "You seem happy."],
  ["seem", "You seem tired."],
  ["select", "Select your favorite."],
  ["self", "She did it herself."],
  ["sell", "They sell fruits."],
  ["sell", "They sell vegetables."],
  ["sell", "They sell vegetables."],
  ["send", "Send me a letter."],
  ["send", "Send me a letter."],
  ["send", "Send me a letter."],
  ["senior", "She is a senior student."],
  ["sense", "He has good sense."],
  ["sense", "He has good sense."],
  ["sense", "It makes sense."],
  ["sent", "She sent a gift."],
  ["sentence", "Write a sentence."],
  ["sentence", "Write a sentence."],
  ["separate", "Separate the papers."],
  ["separate", "Separate the papers."],
  ["series", "She watched the series."],
  ["series", "She watched the series."],
  ["serious", "She is a serious student."],
  ["serious", "She is a serious student."],
  ["serve", "Serve the food."],
  ["serve", "Serve the guests."],
  ["serve", "Serve the guests."],
  ["service", "The service was excellent."],
  ["service", "The service was excellent."],
  ["session", "The session lasted an hour."],
  ["set", "Set the table."],
  ["set", "Set the table."],
  ["set", "Set the table."],
  ["settle", "Let's settle the argument."],
  ["settle", "They will settle in a new city."],
  ["seven", "She has seven books."],
  ["seven", "She has seven books."],
  ["seven", "She has seven candies."],
  ["several", "She has several friends."],
  ["several", "She has several options."],
  ["several", "She has several options."],
  ["severe", "The storm was severe."],
  ["sexual", "They discussed sexual health."],
  ["shade", "They sat in the shade."],
  ["shadow", "She saw her shadow."],
  ["shake", "Shake the bottle well."],
  ["shake", "Shake the bottle well."],
  ["shall", "You shall not pass."],
  ["shall", "You shall not pass."],
  ["shape", "The shape is round."],
  ["share", "She will share her toys."],
  ["share", "She will share her toys."],
  ["sharp", "The knife is sharp."],
  ["shave", "He needs to shave."],
  ["she", "She is my best friend and magician."],
  ["she", "She is my friend."],
  ["she", "She is my friend."],
  ["sheep", "A sheep is grazing."],
  ["sheep", "A sheep is grazing."],
  ["sheet", "He wrote on the sheet."],
  ["sheet", "The sheet is white."],
  ["shelf", "Put it on the shelf."],
  ["shelf", "Put it on the shelf."],
  ["shell", "She found a shell."],
  ["shelter", "They found shelter."],
  ["shield", "He used a shield."],
  ["shift", "They work in shifts."],
  ["shine", "The sun will shine."],
  ["shine", "The sun will shine."],
  ["ship", "A ship is sailing."],
  ["ship", "A ship is sailing."],
  ["shock", "The news was a shock."],
  ["shoe", "He bought a new shoe."],
  ["shoe", "He bought a new shoe."],
  ["shoot", "He will shoot the arrow."],
  ["shoot", "He will shoot the arrow."],
  ["shop", "They went to the shop."],
  ["shop", "They went to the shop."],
  ["shop", "They went to the shop."],
  ["shore", "They walked along the shore."],
  ["shore", "They walked along the shore."],
  ["short", "Her hair is short."],
  ["short", "Her hair is short."],
  ["short", "The dress is short."],
  ["shot", "He took a shot at the goal."],
  ["should", "You should do your homework."],
  ["should", "You should study more."],
  ["should", "You should study more."],
  ["should", "You should wear a cape to the party."],
  ["shoulder", "Her shoulder hurts."],
  ["shoulder", "His shoulder hurts."],
  ["shoulder", "His shoulder hurts."],
  ["shout", "He began to shout."],
  ["shout", "He began to shout."],
  ["show", "Can you show me the secret door?"],
  ["show", "Can you show me?"],
  ["show", "Show me your drawing."],
  ["show", "Show me your drawing."],
  ["shower", "She took a shower."],
  ["shrimp", "They ate shrimp for dinner."],
  ["shrug", "He gave a shrug."],
  ["shut", "Shut the door."],
  ["sick", "She is feeling sick."],
  ["side", "He sat by my side."],
  ["side", "He sat by my side."],
  ["side", "He sat by my side."],
  ["sight", "The sight is beautiful."],
  ["sight", "The sight was breathtaking."],
  ["sign", "She made a sign."],
  ["sign", "She made a sign."],
  ["sign", "She made a sign."],
  ["signal", "She gave a signal."],
  ["silent", "The room was silent."],
  ["silent", "The room was silent."],
  ["silk", "The dress is made of silk."],
  ["silk", "The dress is made of silk."],
  ["silver", "The necklace is silver."],
  ["silver", "The ring is silver."],
  ["similar", "The two books are similar."],
  ["simple", "The instructions are simple."],
  ["simple", "The instructions are simple."],
  ["simple", "The instructions are simple."],
  ["since", "I haven't seen her since then."],
  ["since", "She has been here since morning."],
  ["since", "She has been here since morning."],
  ["sing", "She likes to sing."],
  ["sing", "She likes to sing."],
  ["sing", "She likes to sing."],
  ["single", "Every single detail is important."],
  ["single", "Every single detail is important."],
  ["single", "Every single one is here."],
  ["sink", "The dishes are in the sink."],
  ["sister", "My sister is kind."],
  ["sister", "My sister is kind."],
  ["sit", "Please sit down."],
  ["sit", "Sit down, please."],
  ["sit", "Sit down, please."],
  ["site", "This is the construction site."],
  ["site", "This is the construction site."],
  ["situation", "The situation is under control."],
  ["six", "She has six crayons."],
  ["six", "She has six crayons."],
  ["six", "She has six crayons."],
  ["size", "What size is it?"],
  ["size", "What size is it?"],
  ["size", "What size is it?"],
  ["skill", "He has a skill."],
  ["skill", "She has a skill in painting."],
  ["skill", "She has a skill in painting."],
  ["skin", "Her skin is smooth."],
  ["skin", "Her skin is smooth."],
  ["skirt", "She wore a blue skirt."],
  ["sky", "The sky is blue."],
  ["sky", "The sky is clear."],
  ["slab", "They placed a slab of stone."],
  ["slam", "He will slam the door."],
  ["sleep", "She likes to sleep."],
  ["sleep", "She needs to sleep early."],
  ["sleeve", "The sleeve is too long."],
  ["slender", "She has a slender figure."],
  ["slice", "She ate a slice of pizza."],
  ["slide", "The children like the slide."],
  ["slight", "There is a slight difference."],
  ["slip", "Be careful not to slip."],
  ["slip", "Be careful not to slip."],
  ["slow", "The car is slow."],
  ["slow", "The turtle is slow."],
  ["small", "The puppy is small."],
  ["small", "The puppy is small."],
  ["small", "The puppy is small."],
  ["smart", "He is a smart boy."],
  ["smell", "I smell flowers."],
  ["smell", "The flowers smell nice."],
  ["smile", "She has a beautiful smile."],
  ["smile", "She has a beautiful smile."],
  ["smile", "She has a nice smile."],
  ["smoke", "The smoke alarm went off."],
  ["smooth", "The surface is smooth."],
  ["snack", "She ate a snack."],
  ["snake", "A snake is in the grass."],
  ["sneeze", "He began to sneeze."],
  ["snow", "It will snow tomorrow."],
  ["snow", "It will snow tomorrow."],
  ["so", "He is tired, so he went to bed."],
  ["so", "He was tired, so he took a nap on the cloud."],
  ["so", "He was tired, so he went to bed."],
  ["soap", "She washed her hands with soap."],
  ["social", "She has a social life."],
  ["social", "She has a social life."],
  ["society", "They live in a diverse society."],
  ["sock", "He wore a red sock."],
  ["soft", "The pillow is soft."],
  ["soft", "The pillow is soft."],
  ["soil", "Plants grow in soil."],
  ["soil", "Plants grow in soil."],
  ["sold", "She sold her bike."],
  ["sold", "She sold her car."],
  ["soldier", "The soldier is brave."],
  ["solid", "The table is solid."],
  ["solution", "They found a solution."],
  ["solve", "He can solve the puzzle."],
  ["solve", "He will solve the problem."],
  ["some", "Can I have some of your magic popcorn?"],
  ["some", "Can I have some water?"],
  ["some", "Can I have some water?"],
  ["some", "Can I have some water?"],
  ["someone", "Someone is at the door."],
  ["someone", "Someone is at the door."],
  ["son", "Her son is in kindergarten."],
  ["song", "She sang a song."],
  ["song", "She sang a song."],
  ["song", "She sang a song."],
  ["soon", "We will leave soon for the candy castle."],
  ["soon", "We will leave soon."],
  ["soon", "We will leave soon."],
  ["soon", "We will leave soon."],
  ["sorry", "I am sorry for the mistake."],
  ["sort", "They will sort the letters."],
  ["sort", "They will sort the letters."],
  ["soul", "He has a kind soul."],
  ["sound", "I heard a sound."],
  ["sound", "I heard a sound."],
  ["sound", "I heard a sound."],
  ["soup", "The soup is hot."],
  ["source", "The sun is the source of energy."],
  ["south", "They traveled south."],
  ["south", "They traveled south."],
  ["south", "They traveled south."],
  ["space", "There is a lot of space."],
  ["space", "There is a lot of space."],
  ["space", "There is a lot of space."],
  ["speak", "Please speak clearly."],
  ["speak", "Please speak clearly."],
  ["speak", "Please speak loudly."],
  ["special", "This is a special day."],
  ["special", "This is a special day."],
  ["special", "Today is a special day."],
  ["specific", "She has a specific plan."],
  ["speech", "He gave a speech."],
  ["speech", "He gave a speech."],
  ["speed", "The car has high speed."],
  ["speed", "The car has high speed."],
  ["spell", "Can you spell your name?"],
  ["spell", "Can you spell your name?"],
  ["spend", "She will spend the day at home."],
  ["spend", "She will spend the day at home."],
  ["spend", "They spend time together."],
  ["spider", "A spider is on the wall."],
  ["spin", "The top will spin."],
  ["spirit", "She has a free spirit."],
  ["split", "They will split the cost."],
  ["spoil", "The food will spoil."],
  ["spoke", "She spoke clearly."],
  ["spoke", "She spoke clearly."],
  ["spoon", "She ate with a spoon."],
  ["sport", "She plays a sport."],
  ["sport", "She plays a sport."],
  ["spot", "He found a spot."],
  ["spot", "He found a spot."],
  ["spray", "She used hair spray."],
  ["spread", "She spread butter on the bread."],
  ["spread", "She spread butter on the bread."],
  ["spring", "Flowers bloom in spring."],
  ["spring", "Flowers bloom in spring."],
  ["spring", "Flowers bloom in spring."],
  ["square", "The box is square."],
  ["square", "The box is square."],
  ["squeeze", "Squeeze the lemon."],
  ["stable", "The table is stable."],
  ["stack", "She stacked the books."],
  ["staff", "The staff is friendly."],
  ["stage", "He is on stage."],
  ["stage", "He is on stage."],
  ["stain", "The shirt has a stain."],
  ["stand", "Please stand up."],
  ["stand", "Please stand up."],
  ["stand", "Please stand up."],
  ["standard", "The standard is high."],
  ["star", "A star is shining."],
  ["star", "A star is shining."],
  ["star", "A star is shining."],
  ["stare", "She began to stare."],
  ["start", "Let's start the game of hide and seek with the wizards."],
  ["start", "Let's start the game."],
  ["start", "Let's start the game."],
  ["start", "Let's start the game."],
  ["state", "The state of Texas."],
  ["state", "The state of Texas."],
  ["state", "The state of Texas."],
  ["statement", "He made a statement."],
  ["station", "The train arrived at the station."],
  ["station", "The train arrived at the station."],
  ["stay", "Please stay here."],
  ["stay", "Please stay here."],
  ["stay", "Please stay here."],
  ["stead", "He filled in his stead."],
  ["steady", "Keep a steady pace."],
  ["steal", "He tried to steal a cookie."],
  ["steam", "The steam is hot."],
  ["steam", "The steam is hot."],
  ["steel", "The bridge is made of steel."],
  ["stem", "The stem is green."],
  ["step", "Take a step forward."],
  ["step", "Take a step forward."],
  ["step", "Take a step."],
  ["stick", "He found a stick."],
  ["stick", "He found a stick."],
  ["still", "She is still laughing at the funny joke."],
  ["still", "She is still waiting."],
  ["still", "She is still waiting."],
  ["still", "She is still waiting."],
  ["stitch", "She will stitch the dress."],
  ["stock", "The stock is low."],
  ["stock", "The stock is low."],
  ["stomach", "Her stomach hurts."],
  ["stone", "He threw a stone."],
  ["stone", "The stone is smooth."],
  ["stood", "He stood up quickly."],
  ["stood", "She stood up."],
  ["stop", "Please stop talking."],
  ["stop", "Please stop talking."],
  ["stop", "Please stop talking."],
  ["stop", "Please stop the dancing robot."],
  ["store", "They went to the store."],
  ["store", "They went to the store."],
  ["store", "They went to the store."],
  ["storm", "A storm is coming."],
  ["storm", "A storm is coming."],
  ["story", "She told a funny story."],
  ["story", "She told a funny story."],
  ["story", "She told a funny story."],
  ["story", "She told a story about a flying pig."],
  ["stove", "The stove is hot."],
  ["straight", "Draw a straight line."],
  ["straight", "Draw a straight line."],
  ["strange", "The noise is strange."],
  ["strange", "The noise is strange."],
  ["strategy", "They developed a strategy."],
  ["straw", "She drank with a straw."],
  ["stream", "The stream is flowing."],
  ["stream", "The stream is flowing."],
  ["street", "They walked on the street."],
  ["street", "They walked on the street."],
  ["street", "They walked on the street."],
  ["strength", "She has great strength."],
  ["stretch", "Stretch your arms."],
  ["stretch", "Stretch your arms."],
  ["strict", "The rules are strict."],
  ["strike", "They will strike tomorrow."],
  ["string", "The string is long."],
  ["strip", "He cut a strip of paper."],
  ["stripe", "The zebra has stripes."],
  ["strong", "He is strong."],
  ["strong", "He is strong."],
  ["strong", "He is strong."],
  ["structure", "The structure is solid."],
  ["student", "She is a student."],
  ["student", "She is a student."],
  ["student", "She is a student."],
  ["study", "He needs to study the map to find the treasure."],
  ["study", "She needs to study."],
  ["study", "She needs to study."],
  ["study", "She needs to study."],
  ["stuff", "The bag is full of stuff."],
  ["stuff", "The bag is full of stuff."],
  ["stupid", "That was a stupid mistake."],
  ["style", "She has a unique style."],
  ["style", "She has a unique style."],
  ["subject", "Math is my favorite subject."],
  ["subject", "Math is my favorite subject."],
  ["subject", "Math is my favorite subject."],
  ["submit", "Submit your homework."],
  ["substance", "The substance is sticky."],
  ["subtract", "Subtract two from five."],
  ["subtract", "Subtract two from five."],
  ["succeed", "She will succeed."],
  ["success", "Her success is inspiring."],
  ["success", "Her success is inspiring."],
  ["success", "Her success is inspiring."],
  ["successful", "She is a successful artist."],
  ["such", "This is such a funny movie."],
  ["such", "This is such a nice day."],
  ["such", "This is such a nice day."],
  ["such", "This is such a nice day."],
  ["sudden", "It was a sudden change."],
  ["sudden", "It was a sudden change."],
  ["suddenly", "It suddenly started to rain."],
  ["suffer", "He will suffer consequences."],
  ["suffer", "He will suffer consequences."],
  ["suffix", "Add the suffix to the word."],
  ["sugar", "She added sugar to the tea."],
  ["sugar", "She added sugar to the tea."],
  ["suggest", "What do you suggest?"],
  ["suggest", "What do you suggest?"],
  ["suit", "He wore a suit."],
  ["summer", "We swim in summer."],
  ["summer", "We swim in summer."],
  ["summer", "We swim in summer."],
  ["sun", "The sun is shining."],
  ["sun", "The sun is shining."],
  ["supply", "They need a new supply."],
  ["supply", "They need more supply."],
  ["support", "They support the decision."],
  ["support", "They support the decision."],
  ["support", "They support the team."],
  ["suppose", "I suppose you are right."],
  ["sure", "I am sure the cat can sing."],
  ["sure", "I am sure."],
  ["sure", "I am sure."],
  ["sure", "I am sure."],
  ["surface", "The surface is smooth."],
  ["surface", "The surface is smooth."],
  ["surface", "The surface is smooth."],
  ["surprise", "It was a surprise party."],
  ["surprise", "It was a surprise party."],
  ["surround", "Trees surround the house."],
  ["survey", "They conducted a survey."],
  ["suspect", "The police suspect foul play."],
  ["swallow", "She had to swallow the pill."],
  ["swamp", "The alligator is in the swamp."],
  ["swear", "Do not swear."],
  ["sweat", "He began to sweat."],
  ["sweep", "She will sweep the floor."],
  ["sweet", "The candy is sweet."],
  ["swim", "They swim in the pool."],
  ["swim", "They swim in the pool."],
  ["swing", "The swing is fun."],
  ["switch", "Flip the switch."],
  ["sword", "The knight has a sword."],
  ["syllable", "Apple has two syllables."],
  ["symbol", "A heart is a symbol of love."],
  ["symbol", "The heart is a symbol of love."],
  ["symptom", "She showed symptoms of a cold."],
  ["system", "The system is down."],
  ["system", "The system is down."],
  ["system", "The system is down."],
  ["table", "Set the table."],
  ["table", "Set the table."],
  ["table", "Set the table."],
  ["tackle", "He made a tackle."],
  ["tail", "The dog wagged its tail."],
  ["tail", "The dog wagged its tail."],
  ["take", "Take a cookie from the talking jar."],
  ["take", "Take a seat."],
  ["take", "Take a seat."],
  ["take", "Take a seat."],
  ["tale", "She told a fairy tale."],
  ["talk", "They like to talk."],
  ["talk", "They like to talk."],
  ["talk", "They like to talk."],
  ["tall", "The tree is tall."],
  ["tall", "The tree is tall."],
  ["tank", "The fish is in a tank."],
  ["tap", "She heard a tap on the door."],
  ["tape", "She used tape to fix it."],
  ["target", "They hit the target."],
  ["task", "She completed the task."],
  ["task", "She completed the task."],
  ["task", "She finished the task."],
  ["taste", "The cake tastes good."],
  ["taste", "The cake tastes good."],
  ["tax", "They need to pay tax."],
  ["tax", "They need to pay tax."],
  ["tea", "She drank tea."],
  ["teach", "She likes to teach."],
  ["teach", "She likes to teach."],
  ["teach", "She likes to teach."],
  ["teacher", "He is a good teacher."],
  ["team", "They are a good team."],
  ["team", "They are a strong team."],
  ["team", "They are a strong team."],
  ["tear", "She shed a tear."],
  ["teaspoon", "Add a teaspoon of sugar."],
  ["technology", "Technology is advancing."],
  ["teen", "She is a teen."],
  ["teeth", "Brush your teeth."],
  ["television", "She watched television."],
  ["tell", "Can you tell me a funny story?"],
  ["tell", "Can you tell me a story?"],
  ["tell", "Can you tell me a story?"],
  ["tell", "Can you tell me a story?"],
  ["temperature", "The temperature is high."],
  ["temperature", "The temperature is rising."],
  ["temple", "They visited the temple."],
  ["temporary", "It is a temporary solution."],
  ["ten", "She has ten books."],
  ["ten", "She has ten books."],
  ["ten", "She has ten books."],
  ["tend", "They tend to the garden."],
  ["tend", "They tend to the garden."],
  ["term", "The school term is ending."],
  ["term", "The school term is ending."],
  ["term", "The term ends soon."],
  ["test", "She passed the test."],
  ["test", "She passed the test."],
  ["test", "She passed the test."],
  ["text", "She sent a text message."],
  ["than", "She is taller than him."],
  ["than", "She is taller than him."],
  ["than", "She is taller than him."],
  ["than", "She is taller than the magic mushroom."],
  ["thank", "Thank you for the gift."],
  ["thank", "Thank you for the gift."],
  ["thank", "Thank you for the gift."],
  ["that", "That is my book."],
  ["that", "That is my book."],
  ["that", "That is my book."],
  ["that", "That is my invisible friend."],
  ["the", "The cat is cute."],
  ["the", "The cat is cute."],
  ["the", "The cat is cute."],
  ["the", "The cat is playing the piano."],
  ["their", "This is their house."],
  ["their", "This is their house."],
  ["their", "This is their house."],
  ["their", "This is their magical garden."],
  ["them", "I gave them each a rainbow lollipop."],
  ["them", "I gave them gifts."],
  ["them", "I gave them gifts."],
  ["them", "I gave them gifts."],
  ["theme", "The theme of the party is fun."],
  ["themselves", "They did it themselves."],
  ["then", "We ate, then we played with the unicorn."],
  ["then", "We ate, then we played."],
  ["then", "We ate, then we played."],
  ["then", "We ate, then we played."],
  ["theory", "She has a theory."],
  ["theory", "She has a theory."],
  ["there", "There is a cat."],
  ["there", "There is a cat."],
  ["there", "There is a cat."],
  ["there", "There is a talking parrot in the tree."],
  ["therefore", "It rained, therefore we stayed in."],
  ["therefore", "It rained, therefore we stayed in."],
  ["these", "These are my dancing shoes."],
  ["these", "These are my friends."],
  ["these", "These are my friends."],
  ["these", "These are my friends."],
  ["they", "They are going to the moon party."],
  ["they", "They are going to the park."],
  ["they", "They are going to the park."],
  ["they", "They are playing outside."],
  ["thick", "The book is thick."],
  ["thick", "The book is thick."],
  ["thin", "The paper is thin."],
  ["thin", "The paper is thin."],
  ["thing", "What is that funny thing?"],
  ["thing", "What is that thing?"],
  ["thing", "What is that thing?"],
  ["thing", "What is that thing?"],
  ["think", "I think it's a good idea."],
  ["think", "I think it's a good idea."],
  ["think", "I think it's a good idea."],
  ["think", "I think the dog can talk."],
  ["third", "She finished in third place."],
  ["third", "She finished in third place."],
  ["third", "She finished in third place."],
  ["thirsty", "She is thirsty."],
  ["thirty", "She is thirty years old."],
  ["this", "This is my book."],
  ["this", "This is my book."],
  ["this", "This is my pencil."],
  ["this", "This is my pet dragon."],
  ["those", "Those are my magic beans."],
  ["those", "Those are my shoes."],
  ["those", "Those are my shoes."],
  ["those", "Those are my shoes."],
  ["though", "He is kind, though shy."],
  ["though", "He is kind, though shy."],
  ["though", "He is kind, though shy."],
  ["thought", "She had a thought."],
  ["thought", "She had a thought."],
  ["thought", "She had a thought."],
  ["thousand", "She counted to a thousand."],
  ["thousand", "She counted to a thousand."],
  ["thousand", "She counted to a thousand."],
  ["thread", "The thread is red."],
  ["threat", "There is no threat."],
  ["three", "She has three dogs."],
  ["three", "She has three dogs."],
  ["three", "She has three dogs."],
  ["throat", "Her throat hurts."],
  ["through", "Walk through the door."],
  ["through", "Walk through the door."],
  ["through", "Walk through the door."],
  ["throughout", "It rained throughout the day."],
  ["throw", "Throw the ball."],
  ["throw", "Throw the ball."],
  ["throw", "Throw the ball."],
  ["thumb", "He hurt his thumb."],
  ["thunder", "The thunder was loud."],
  ["thus", "Thus, we decided to leave."],
  ["thus", "Thus, we decided to leave."],
  ["thus", "Thus, we decided to leave."],
  ["ticket", "She bought a ticket."],
  ["tide", "The tide is high."],
  ["tie", "He wore a tie."],
  ["tie", "He wore a tie."],
  ["tight", "The lid is tight."],
  ["till", "Wait till morning."],
  ["time", "What time is it in fairyland?"],
  ["time", "What time is it?"],
  ["time", "What time is it?"],
  ["time", "What time is it?"],
  ["tiny", "The ant is tiny."],
  ["tiny", "The ant is tiny."],
  ["tip", "The tip of the pen is sharp."],
  ["tire", "The car needs a new tire."],
  ["tire", "The car needs a new tire."],
  ["tired", "He is tired."],
  ["tissue", "She grabbed a tissue."],
  ["title", "The book's title is catchy."],
  ["to", "She went to school."],
  ["to", "She went to school."],
  ["to", "She went to school."],
  ["to", "She went to the wizard's tower."],
  ["toast", "She made toast for breakfast."],
  ["today", "Today is a good day."],
  ["today", "Today is a good day."],
  ["together", "We play together with the talking toys."],
  ["together", "We play together."],
  ["together", "We play together."],
  ["together", "We play together."],
  ["toilet", "The toilet is clean."],
  ["told", "She told me a secret."],
  ["tomorrow", "We will go tomorrow."],
  ["tomorrow", "We will meet tomorrow."],
  ["tone", "The tone of her voice was kind."],
  ["tone", "The tone was friendly."],
  ["tongue", "She bit her tongue."],
  ["tonight", "We will meet tonight."],
  ["tonight", "We will watch a movie tonight."],
  ["too", "I want to go too."],
  ["too", "I want to go too."],
  ["too", "I want to ride the flying carpet too."],
  ["took", "He took the pen."],
  ["tool", "He used a tool to fix it."],
  ["tool", "He used a tool."],
  ["tooth", "His tooth hurts."],
  ["top", "She climbed to the top."],
  ["top", "She climbed to the top."],
  ["top", "She climbed to the top."],
  ["topic", "The topic of the discussion was science."],
  ["torch", "He used a torch to see in the dark."],
  ["total", "The total is $10."],
  ["total", "The total is five dollars."],
  ["touch", "Do not touch that."],
  ["touch", "Do not touch the artwork."],
  ["tough", "The meat is tough."],
  ["tough", "The meat is tough."],
  ["tour", "They went on a tour of the city."],
  ["tournament", "He won the chess tournament."],
  ["toward", "She walked toward the door."],
  ["toward", "She walked toward the door."],
  ["toward", "She walked toward the door."],
  ["tower", "The tower is tall."],
  ["town", "The town is quiet."],
  ["town", "They live in a small town."],
  ["town", "They live in a small town."],
  ["toy", "The child played with a toy."],
  ["trace", "She traced the drawing."],
  ["track", "They ran on the track."],
  ["track", "They ran on the track."],
  ["trade", "They trade goods with other countries."],
  ["trade", "They trade goods with other countries."],
  ["trade", "They trade goods."],
  ["traditional", "They follow traditional customs."],
  ["traffic", "The traffic is heavy."],
  ["tragedy", "The play is a tragedy."],
  ["trail", "They hiked on a trail."],
  ["train", "The train is fast."],
  ["train", "They took a train to the city."],
  ["training", "She completed the training."],
  ["transfer", "She will transfer schools."],
  ["transform", "The caterpillar will transform into a butterfly."],
  ["translate", "She can translate Spanish to English."],
  ["transparent", "The glass is transparent."],
  ["trap", "They set a trap for the mouse."],
  ["travel", "They love to travel."],
  ["travel", "They love to travel."],
  ["travel", "They love to travel."],
  ["treasure", "They found buried treasure."],
  ["treat", "She gave the dog a treat."],
  ["treat", "She gave the dog a treat."],
  ["treatment", "The treatment was effective."],
  ["tree", "The tree is tall."],
  ["tree", "The tree is tall."],
  ["tree", "The tree is tall."],
  ["tremble", "He began to tremble in fear."],
  ["trial", "The trial lasted for days."],
  ["trial", "The trial lasted for days."],
  ["triangle", "Draw a triangle."],
  ["triangle", "Draw a triangle."],
  ["tribe", "The tribe has unique customs."],
  ["trick", "He played a trick on her."],
  ["trip", "They went on a trip."],
  ["trip", "They went on a trip."],
  ["trip", "They went on a trip."],
  ["trophy", "She won a trophy."],
  ["trouble", "He is in trouble."],
  ["trouble", "He is in trouble."],
  ["trouble", "He is in trouble."],
  ["truck", "The truck is big."],
  ["truck", "The truck is large."],
  ["true", "The story is true."],
  ["true", "The story is true."],
  ["true", "The story is true."],
  ["truly", "She truly believes it."],
  ["trust", "You can trust him."],
  ["truth", "She always tells the truth."],
  ["truth", "She always tells the truth."],
  ["try", "Try to do it."],
  ["try", "Try to do your best."],
  ["try", "Try to do your best."],
  ["tube", "The tube is hollow."],
  ["tube", "The tube is long."],
  ["tunnel", "They drove through a tunnel."],
  ["turkey", "They ate turkey for dinner."],
  ["turn", "Turn the page."],
  ["turn", "Turn the page."],
  ["turn", "Turn the page."],
  ["turtle", "A turtle moves slowly."],
  ["TV", "They watched TV."],
  ["twelve", "She is twelve years old."],
  ["twenty", "He has twenty dollars."],
  ["twenty", "She is twenty years old."],
  ["twice", "I have been there twice."],
  ["twin", "They are twin sisters."],
  ["two", "She has two dogs."],
  ["two", "She has two pet dragons."],
  ["two", "She has two pets."],
  ["two", "She has two pets."],
  ["type", "This is a type of fruit."],
  ["type", "This is a type of fruit."],
  ["type", "This is a type of fruit."],
  ["typical", "It was a typical day."],
  ["ugly", "The building is ugly."],
  ["umbrella", "She took an umbrella because it was raining."],
  ["unable", "She was unable to attend."],
  ["uncle", "Her uncle is visiting."],
  ["under", "The cat is under the magician's hat."],
  ["under", "The cat is under the table."],
  ["under", "The cat is under the table."],
  ["under", "The cat is under the table."],
  ["undergo", "He will undergo surgery."],
  ["understand", "I understand the problem."],
  ["understand", "I understand the problem."],
  ["uniform", "He wears a uniform to school."],
  ["union", "They joined a union."],
  ["union", "They joined a union."],
  ["unique", "Every person is unique."],
  ["unit", "This is a unit of measurement."],
  ["unit", "This is a unit of measurement."],
  ["unit", "This is a unit of measurement."],
  ["unite", "They will unite for a common cause."],
  ["universe", "The universe is vast."],
  ["university", "She studies at a university."],
  ["unknown", "The author is unknown."],
  ["unless", "We won't go unless it stops raining."],
  ["until", "Wait until I return."],
  ["until", "Wait until I return."],
  ["until", "Wait until I return."],
  ["unusual", "The bird has unusual feathers."],
  ["up", "She went up the rainbow ladder."],
  ["up", "She went up the stairs."],
  ["up", "She went up the stairs."],
  ["upon", "Once upon a time."],
  ["upon", "Once upon a time."],
  ["upon", "Once upon a time."],
  ["upper", "He lives on the upper floor."],
  ["upset", "She was upset by the news."],
  ["urban", "They live in an urban area."],
  ["urge", "She felt the urge to run."],
  ["us", "They gave us flowers."],
  ["us", "They gave us flowers."],
  ["us", "They gave us flowers."],
  ["us", "They gave us glowing bracelets."],
  ["use", "Can I use your magic wand?"],
  ["use", "Can I use your pen?"],
  ["use", "Can I use your pen?"],
  ["use", "Can I use your pen?"],
  ["used", "She used a pencil."],
  ["useful", "The tool is useful."],
  ["user", "The app has many users."],
  ["usual", "It is his usual routine."],
  ["usual", "This is the usual way."],
  ["usually", "It is usually hot in summer."],
  ["utility", "The utility bill is due."],
  ["vacation", "They went on vacation."],
  ["valley", "The valley is green."],
  ["valley", "The valley is green."],
  ["valuable", "The ring is valuable."],
  ["value", "The value of the coin is high."],
  ["value", "The value of the coin is high."],
  ["value", "The value of the coin is high."],
  ["vampire", "The movie is about a vampire."],
  ["van", "They rented a van."],
  ["vanish", "The magician made the coin vanish."],
  ["variable", "The weather is variable."],
  ["variation", "There is a variation in color."],
  ["variety", "She grows a variety of flowers."],
  ["various", "She has various hobbies."],
  ["various", "She has various hobbies."],
  ["vary", "The sizes vary."],
  ["vast", "The ocean is vast."],
  ["vegetable", "She likes to eat vegetables."],
  ["vehicle", "They bought a new vehicle."],
  ["venture", "They will venture into the forest."],
  ["verb", "Run is a verb."],
  ["version", "This is the latest version."],
  ["very", "She is very good at making funny faces."],
  ["very", "She is very happy."],
  ["very", "She is very happy."],
  ["very", "She is very happy."],
  ["vessel", "The vessel is old."],
  ["veteran", "He is a war veteran."],
  ["victim", "The victim was helped."],
  ["victim", "The victim was helped."],
  ["victory", "They celebrated their victory."],
  ["video", "They watched a video."],
  ["view", "The view from the hill is beautiful."],
  ["view", "The view from the hill is beautiful."],
  ["view", "The view is beautiful."],
  ["village", "The village is small."],
  ["village", "They live in a small village."],
  ["violence", "They condemned the violence."],
  ["violin", "She plays the violin."],
  ["virtual", "The meeting was virtual."],
  ["virus", "The virus spread quickly."],
  ["visible", "The moon is visible tonight."],
  ["vision", "She has good vision."],
  ["visit", "She will visit her friend."],
  ["visit", "They visit their grandparents."],
  ["visit", "They visit their grandparents."],
  ["visitor", "They had a visitor."],
  ["visual", "The presentation was visual."],
  ["vital", "Water is vital for life."],
  ["voice", "Her voice is soft."],
  ["voice", "She has a soft voice."],
  ["voice", "She has a soft voice."],
  ["volume", "Turn up the volume."],
  ["vote", "They voted in the election."],
  ["vote", "They voted in the election."],
  ["vowel", "A is a vowel."],
  ["vowel", "A is a vowel."],
  ["voyage", "They went on a long voyage."],
  ["wagon", "The horse pulled the wagon."],
  ["wait", "Please wait here."],
  ["wait", "Please wait here."],
  ["wait", "Please wait here."],
  ["wake", "She will wake up early."],
  ["walk", "They walk to school."],
  ["walk", "They walk to school."],
  ["walk", "They walk to school."],
  ["wall", "The wall is tall."],
  ["wall", "The wall is tall."],
  ["wall", "The wall is tall."],
  ["want", "I want to play with the talking robot."],
  ["want", "I want to play."],
  ["want", "She wants to play outside."],
  ["want", "She wants to play outside."],
  ["war", "The war ended."],
  ["war", "They learned about the war."],
  ["war", "They learned about the war."],
  ["warm", "The blanket is warm."],
  ["warm", "The blanket is warm."],
  ["warn", "She warned him of the danger."],
  ["was", "He was here, but then he turned into a frog."],
  ["was", "He was here."],
  ["wash", "She will wash the dishes."],
  ["wash", "She will wash the dishes."],
  ["waste", "Do not waste food."],
  ["watch", "They watch TV."],
  ["watch", "They watch TV."],
  ["watch", "They watched the flying squirrels perform tricks."],
  ["watch", "They watched TV."],
  ["water", "Can I have some water?"],
  ["water", "She drank a glass of water."],
  ["water", "She drank a glass of water."],
  ["water", "She splashed in the purple water."],
  ["wave", "She gave a friendly wave."],
  ["wave", "She gave a wave."],
  ["wax", "The candle is made of wax."],
  ["way", "Show me the way to the gummy bear forest."],
  ["way", "Show me the way."],
  ["way", "Show me the way."],
  ["way", "Show me the way."],
  ["we", "We are friends with the talking tree."],
  ["we", "We are friends."],
  ["we", "We are friends."],
  ["we", "We are friends."],
  ["weak", "He felt weak after the flu."],
  ["wealth", "They have great wealth."],
  ["weapon", "The soldier had a weapon."],
  ["wear", "She likes to wear dresses."],
  ["wear", "She likes to wear dresses."],
  ["wear", "She likes to wear dresses."],
  ["weather", "The weather is nice."],
  ["weather", "The weather is nice."],
  ["web", "The spider spun a web."],
  ["wedding", "They attended the wedding."],
  ["week", "We will meet next week."],
  ["week", "We will meet next week."],
  ["week", "We will meet next week."],
  ["weekend", "They relax on the weekend."],
  ["weigh", "She will weigh the fruit."],
  ["weight", "The weight is heavy."],
  ["weight", "The weight is heavy."],
  ["weight", "The weight of the box is heavy."],
  ["welcome", "You are welcome here."],
  ["well", "You did well at the funny face contest."],
  ["well", "You did well."],
  ["well", "You did well."],
  ["well", "You did well."],
  ["went", "She went to the moon in her dreams."],
  ["went", "She went to the store."],
  ["were", "They were here."],
  ["were", "They were there when the elephant danced."],
  ["west", "The sun sets in the west."],
  ["west", "The sun sets in the west."],
  ["west", "The sun sets in the west."],
  ["wet", "The floor is wet."],
  ["whale", "A whale is a large animal."],
  ["what", "What did the singing banana say?"],
  ["what", "What is this?"],
  ["what", "What is this?"],
  ["what", "What is your name?"],
  ["whatever", "Do whatever you like."],
  ["wheat", "They grow wheat on the farm."],
  ["wheel", "The wheel is round."],
  ["wheel", "The wheel is round."],
  ["when", "When can we ride the magic carpet?"],
  ["when", "When is your birthday?"],
  ["when", "When is your birthday?"],
  ["when", "When is your birthday?"],
  ["where", "Where is the book?"],
  ["where", "Where is the book?"],
  ["where", "Where is the book?"],
  ["where", "Where is the dragon's hidden treasure?"],
  ["whether", "She will come whether it rains or not."],
  ["whether", "She will decide whether to go."],
  ["whether", "She will decide whether to go."],
  ["which", "Which cloud should we bounce on first?"],
  ["which", "Which one is yours?"],
  ["which", "Which one is yours?"],
  ["which", "Which one is yours?"],
  ["while", "Please wait while I finish."],
  ["while", "Please wait while I finish."],
  ["while", "Please wait while I finish."],
  ["while", "Please wait while the cookie monster bakes more cookies."],
  ["whisper", "She whispered a secret."],
  ["whistle", "He can whistle a tune."],
  ["white", "The snow is white."],
  ["white", "The snow is white."],
  ["white", "The snow is white."],
  ["who", "Who is that?"],
  ["who", "Who is that?"],
  ["who", "Who is that?"],
  ["who", "Who took my invisible ink pen?"],
  ["whole", "She ate the whole cake."],
  ["whole", "She ate the whole cake."],
  ["whole", "She ate the whole cake."],
  ["whom", "To whom it may concern."],
  ["whom", "To whom it may concern."],
  ["whose", "Whose book is this?"],
  ["whose", "Whose book is this?"],
  ["whose", "Whose book is this?"],
  ["why", "Why are you sad?"],
  ["why", "Why are you sad?"],
  ["why", "Why are you sad?"],
  ["why", "Why does the cat have a mustache?"],
  ["wide", "The river is wide."],
  ["wide", "The river is wide."],
  ["wide", "The road is wide."],
  ["wife", "His wife is a doctor."],
  ["wife", "His wife is a doctor."],
  ["wild", "The animal is wild."],
  ["will", "She will bring the talking teddy bear."],
  ["will", "She will call you later."],
  ["will", "She will call you later."],
  ["will", "She will call you later."],
  ["willing", "He is willing to help."],
  ["win", "They want to win."],
  ["win", "They want to win."],
  ["win", "They want to win."],
  ["wind", "The wind is strong."],
  ["wind", "The wind is strong."],
  ["wind", "The wind is strong."],
  ["window", "Open the window."],
  ["window", "Open the window."],
  ["window", "Open the window."],
  ["wine", "They drank wine."],
  ["wing", "A bird has a wing."],
  ["winner", "She is the winner."],
  ["winter", "Winter is cold."],
  ["winter", "Winter is cold."],
  ["wipe", "Please wipe the table."],
  ["wire", "The wire is thin."],
  ["wisdom", "He is known for his wisdom."],
  ["wise", "She made a wise decision."],
  ["wish", "I wish you happiness."],
  ["wish", "Make a wish."],
  ["wish", "Make a wish."],
  ["with", "I went with her to the enchanted forest."],
  ["with", "I went with her."],
  ["with", "I went with her."],
  ["with", "I went with her."],
  ["withdraw", "She will withdraw money."],
  ["within", "Look within the box."],
  ["within", "Look within the box."],
  ["without", "She left without her phone."],
  ["without", "She left without her phone."],
  ["wolf", "A wolf howled in the distance."],
  ["woman", "The woman is kind."],
  ["woman", "The woman is kind."],
  ["woman", "The woman is kind."],
  ["wonder", "I wonder what will happen."],
  ["wonder", "I wonder what will happen."],
  ["wonder", "I wonder what will happen."],
  ["wood", "The table is made of wood."],
  ["wood", "The table is made of wood."],
  ["wool", "Her sweater is made of wool."],
  ["word", "Spell the word."],
  ["word", "Spell the word."],
  ["word", "Spell the word."],
  ["word", "The magic word is Abracadabra."],
  ["work", "He has to work at the jellybean factory."],
  ["work", "He has to work."],
  ["work", "He has to work."],
  ["work", "I have to work."],
  ["worker", "The worker is efficient."],
  ["worker", "The worker is efficient."],
  ["world", "The world is big."],
  ["world", "The world is full of magical creatures."],
  ["world", "The world is large."],
  ["world", "The world is large."],
  ["worm", "A worm is in the soil."],
  ["worry", "Don't worry about it."],
  ["worth", "The painting is worth a lot."],
  ["would", "Would you like some tea?"],
  ["would", "Would you like some tea?"],
  ["would", "Would you like some tea?"],
  ["would", "Would you like to ride the rainbow slide?"],
  ["wound", "The wound is healing."],
  ["wrap", "Please wrap the gift."],
  ["wreck", "They discovered a shipwreck."],
  ["wrist", "Her wrist is sore."],
  ["write", "Can you write a story about the laughing lion?"],
  ["write", "Can you write your name?"],
  ["write", "Can you write your name?"],
  ["write", "Can you write your name?"],
  ["writer", "She is a famous writer."],
  ["writer", "She is a famous writer."],
  ["writing", "Her writing is neat."],
  ["written", "It is written here."],
  ["wrong", "The answer is wrong."],
  ["wrong", "The answer is wrong."],
  ["wrong", "The answer is wrong."],
  ["yard", "The kids are playing in the yard."],
  ["yard", "The kids are playing in the yard."],
  ["yard", "The kids are playing in the yard."],
  ["year", "This year is going to be magical."],
  ["year", "This year is great."],
  ["year", "This year is great."],
  ["year", "This year is great."],
  ["yell", "He let out a yell."],
  ["yellow", "The sun is yellow."],
  ["yellow", "The sun is yellow."],
  ["yes", "Yes, I agree."],
  ["yes", "Yes, I agree."],
  ["yes", "Yes, I like it."],
  ["yes", "Yes, the unicorn really can dance."],
  ["yesterday", "She called me yesterday."],
  ["yet", "She has not finished yet."],
  ["yet", "She hasn't finished yet."],
  ["yet", "She hasn't finished yet."],
  ["you", "You are my friend."],
  ["you", "You are my friend."],
  ["you", "You are my friend."],
  ["you", "You are my superhero sidekick."],
  ["young", "The young girl is singing."],
  ["young", "The young girl is singing."],
  ["young", "The young girl is singing."],
  ["young", "The young wizard cast a funny spell."],
  ["your", "Your book is here."],
  ["your", "Your book is here."],
  ["your", "Your book is here."],
  ["your", "Your hat is full of glitter."],
  ["yourself", "You did it yourself."],
  ["youth", "She has a youthful spirit."],
  ["zebra", "A zebra has stripes."],
  ["zero", "The temperature is zero degrees."],
  ["zoo", "They visited the zoo."],
  ["zoo", "We went to the zoo to see the juggling monkeys."],
  ["zoo", "We went to the zoo."],
  ["zoom", "The camera can zoom in."],
];
