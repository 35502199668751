import { words } from "../resource/topWords";
import { getRandomInt, getRandomIntExcept, unit } from "../utils";

export function getRandomSpellingWord() {
  const index = getRandomInt(words.length - 1, 0);
  return words[index];
}

export function toNumber(input) {
  const numberMap = {
    O: 0,
    o: 0,
    a: 9,
    q: 9,
    b: 6,
    g: 9,
    s: 5,
    S: 5,
    z: 2,
    Z: 2,
    B: 8,
    A: 4,
    G: 6,
    I: 1,
  };
  return numberMap[input] !== undefined ? numberMap[input] : input;
}

export function makeBalloonQuestion() {
  const n1 = getRandomInt(51, 10);
  const n2 = getRandomInt(n1 / 2, (-1 * n1) / 2);
  const n3 = -1 * getRandomInt(n1 + n2);

  function get2nd(num) {
    if (num === 0) return "";

    return num < 0
      ? `She gave away ${Math.abs(num)}.`
      : `She bought ${num} more.`;
  }
  return {
    context: `Nira bought ${n1} balloons. ${get2nd(n2)} ${
      n3 ? `${Math.abs(n3)} balloon popped.` : ""
    } How many balloons does Nira have left?`,
    result: n1 + n2 + n3,
  };
}

export function makeAppleQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `Nick has ${n1} red apples and ${Math.abs(
      n2
    )} yellow apples. How many more red apples than yellow apples does he have?`,
    result: n1 + n2,
  };
}

export function makeBirdQuestion() {
  const n1 = getRandomInt(51, 5);
  const n2 = -1 * getRandomInt(n1, 1);
  return {
    context: `There are ${n1} birds on an apple tree. A squirrel chases ${Math.abs(
      n2
    )} away. How many birds are left on the tree?`,
    result: n1 + n2,
  };
}

export function makePencilQuestion() {
  const n1 = getRandomInt(20, 5);
  const n2 = getRandomInt(20, 2);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);
  function get3rd(num) {
    return num > 0
      ? `Moon gave him ${num} more.`
      : `He gave Moon ${Math.abs(num)}.`;
  }
  return {
    context: `Jamie has ${n1} pencils in his backpack and ${n2} pencils on the table.
              ${get3rd(n3)}
              How many pencils does he have altogether?`,
    result: n1 + n2 + n3,
  };
}

export function makeCandyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  function getNext(num) {
    return num > 0
      ? `Luna has ${num} more than Moon`
      : `Luna has ${abs(num)} fewer candies than Moon`;
  }
  return {
    context: `Moon has ${n1} candies. ${getNext(
      n2
    )}. How many candies does Luna have?`,
    result: n1 + n2,
  };
}

export function makeOrangeQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `A basket contains ${n1} oranges. If ${abs(
      n2
    )} oranges are taken out and ${n3} more are added, how many oranges are in the basket now?`,
    result: n1 + n2 + n3,
  };
}

export function makeBookQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `Lisa has ${n1} books. She lends ${abs(
      n2
    )} books to her friend and then buys ${n3} more. How many books does Lisa have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMarbleQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 5);
  return {
    context: `John has ${n1} marbles. He loses ${abs(
      n2
    )} marbles and then finds ${n3} more. How many marbles does John have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeMoneyQuestion() {
  const n1 = getRandomInt(30, 5);
  const n2 = getRandomInt(20, 5);
  const n3 = -1 * getRandomInt(n1 + n2, 1);
  return {
    context: `Mike has ${n1} dollars. He earns ${n2} dollars from chores then spends ${abs(
      n3
    )} dollars on toys. How much money does Mike have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeStudentQuestion() {
  const n1 = getRandomInt(50, 20);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A classroom has ${n1} students. ${abs(
      n2
    )} students leave for a field trip, and ${n3} new students join the class. How many students are in the classroom now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFliesQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 1);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} flies on a pizza. Jame swat ${abs(
      n2
    )} with his hands. But the pizza is so good that ${n3} more flies come. How many flies are on the pizza?`,
    result: n1 + n2 + n3,
  };
}

export function makeCowQuestion() {
  const n1 = getRandomInt(50, 5);
  const n2 = getRandomIntExcept(n1, -1 * n1, 0);
  const n3 = getRandomIntExcept(n1 + n2, -1 * (n1 + n2), 0);
  function buySell(num) {
    return num > 0 ? `buys ${abs(num)}` : `sells ${abs(num)}`;
  }
  return {
    context: `A farmer has ${n1} cows. He ${buySell(
      n2
    )} cows in the morning and then ${buySell(n3)} ${
      n2 * n3 > 0 ? "more" : ""
    } in the afternoon. How many cows does the farmer have?`,
    result: n1 + n2 + n3,
  };
}

export function makeBananaQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A monkey has ${n1} bananas. A parrot has ${moreLessThan(
      n2
    )} the monkey. How many bananas does the parrot have?`,
    result: n1 + n2,
  };
}

export function makePirateQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A pirate has ${n1} gold coins. A sailor has ${moreLessThan(
      n2
    )}  the pirate. How many gold coins does the sailor have?`,
    result: n1 + n2,
  };
}

export function makeDragonQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A dragon has ${n1} jewels. A knight has ${moreLessThan(
      n2
    )} the dragon. How many jewels does the knight have?`,
    result: n1 + n2,
  };
}

export function makeCatDogFishQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(n1, -1 * n1);
  return {
    context: `A cat has ${n1} fish. A dog has ${moreLessThan(
      n2
    )} the cat. How many fish does the dog have?`,
    result: n1 + n2,
  };
}

export function makeAntQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `There are ${n1} ants at a picnic. If ${abs(
      n2
    )} ants leave but ${n3} more join, how many ants are at the picnic now?`,
    result: n1 + n2 + n3,
  };
}

export function makeFrogQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = -1 * getRandomInt(n1, 2);
  const n3 = getRandomInt(20, 2);
  return {
    context: `A frog has ${n1} flies. It eats ${abs(
      n2
    )} but catches ${n3} more. How many flies does the frog have now?`,
    result: n1 + n2 + n3,
  };
}

export function makeSophiaCandyQuestion() {
  const n1 = getRandomInt(50, 10);
  const n2 = getRandomInt(20, 2);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);

  const phrase =
    n3 < 0
      ? `Later, she gave {n3} candies to her friends at a party`
      : `Later, her friends gave her {n3} candies at a party`;
  return contextResult(
    `Sophia had {n1} candies in her bag. She went to the store and bought {n2} more candies. ${phrase}. How many candies does Sophia have now?`,
    { n1, n2, n3 }
  );
}

export function makeAppleTreeQuestion() {
  const n1 = getRandomInt(50, 20);
  const n2 = -1 * getRandomInt(n1 - 5, 1);
  const n3 = -1 * getRandomInt(n1 + n2);

  return contextResult(
    `A tree in the orchard had {n1} apples hanging on its branches. After a strong wind, {n2} apples fell to the ground. The farmer then picked {n3} more apples from the tree. How many apples are left hanging on the tree?`,
    { n1, n2, n3 }
  );
}

export function makeCupcakeQuestion() {
  const n1 = getRandomInt(50, 20);
  const n2 = -1 * getRandomInt(n1 - 5, 1);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);

  const phrase =
    n3 > 0
      ? `so Katie decided to bake {n3} more cupcakes`
      : `Katie gave {n3} more cupcakes to her sister`;
  return contextResult(
    `Katie baked {n1} cupcakes for her birthday party. Her friends ate {n2} cupcakes during the party, ${phrase}. How many cupcakes does Katie have now?`,
    { n1, n2, n3 }
  );
}

export function makeBusQuestion() {
  const n1 = getRandomInt(30, 10);
  const n2 = getRandomIntExcept(20, -1 * (n1 - 5), 0);
  const n3 = getRandomIntExcept(20, -1 * (n1 + n2), 0);

  const p2 =
    n2 > 0
      ? `{n2} ${unit(n2, "student")} got on the bus`
      : `{n2} ${unit(n2, "student")} got off the bus`;

  const p3 =
    n3 > 0
      ? `and {n3} ${unit(n3, "student")} got on at the next stop`
      : `and {n3} ${unit(n3, "student")} got off at the next stop`;
  return contextResult(
    `A school bus started its morning route with {n1} students. At the first stop, ${p2}, ${p3}. How many students are on the bus now?`,
    { n1, n2, n3 }
  );
}

function contextResult(context, numbers) {
  // console.log(numbers);
  return {
    context: applyTemplate(context, numbers),
    result: Object.keys(numbers).reduce(
      (partialSum, key) => partialSum + numbers[key],
      0
    ),
  };
}

function applyTemplate(template, params) {
  return template.replace(/\{(\w+)\}/g, (match, key) => {
    return key in params ? abs(params[key]) : match;
  });
}

function moreLessThan(num) {
  if (num > 0) {
    return `${num} more than`;
  }

  const lessWord = getRandomInt(2) === 1 ? "less" : "fewer";
  return `${abs(num)} ${lessWord} than`;
}

export function abs(num) {
  return Math.abs(num);
}
