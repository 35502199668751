// import { Speaker } from "../speaker/Speaker";

import DraftInput from "../component/DraftInput";
import { talk } from "../speaker/Speaker";
import { getRandomInt } from "../utils";
import {
  makeAntQuestion,
  makeAppleQuestion,
  makeAppleTreeQuestion,
  makeBalloonQuestion,
  makeBananaQuestion,
  makeBirdQuestion,
  makeBookQuestion,
  makeBusQuestion,
  makeCandyQuestion,
  makeCatDogFishQuestion,
  makeCowQuestion,
  makeCupcakeQuestion,
  makeDragonQuestion,
  makeFliesQuestion,
  makeFrogQuestion,
  makeMarbleQuestion,
  makeMoneyQuestion,
  makeOrangeQuestion,
  makePencilQuestion,
  makePirateQuestion,
  makeSophiaCandyQuestion,
  makeStudentQuestion,
  toNumber,
} from "./QuestionUtil";

export function MathWordQuestion({ id = crypto.randomUUID(), onCheckResult }) {
  let isRead = false;

  const question = QuestionBank.makeQuestion();

  function isCorrect(answer) {
    return getResult() == toNumber(answer.replaceAll(" ", ""));
  }

  function getResult() {
    return question.result;
  }

  function getQuestion() {
    return question.context;
  }

  function getHints() {
    return ["Can you help me solve this?"];
  }

  function renderHints(className, onClick) {
    return getHints().map((hint, id) => (
      <div
        key={`hint-${id}`}
        className={`hint ${className}`}
        onClick={() => onClick(hint)}
      >
        {hint}
      </div>
    ));
  }

  function renderQuestion(className, onClick) {
    return <div className="questionTalk bubble">{getQuestion()}</div>;
  }

  async function read() {
    if (!isRead) {
      await talk(getHints()[0]);
      await talk(getQuestion());
    }
    isRead = true;
  }

  function renderInput(children) {
    return (
      <DraftInput question={this} onCheckResult={onCheckResult}>
        {children}
      </DraftInput>
    );
  }

  return {
    id,
    type: "MATH_WORD_QUESTION",
    isCorrect,
    getQuestion,
    getHints,
    renderHints,
    renderQuestion,
    read,
    renderInput,
    // renderAnswer,
  };
}

const QuestionBank = {
  questions: [
    makeSophiaCandyQuestion,
    makeAppleTreeQuestion,
    makeCupcakeQuestion,
    makeBusQuestion,
    makeBalloonQuestion,
    makeAppleQuestion,
    makeBirdQuestion,
    makePencilQuestion,
    makeCandyQuestion,
    makeOrangeQuestion,
    makeBookQuestion,
    makeMarbleQuestion,
    makeMoneyQuestion,
    makeStudentQuestion,
    makeFliesQuestion,
    makeMarbleQuestion,
    makeCowQuestion,
    makeBananaQuestion,
    makePirateQuestion,
    makeDragonQuestion,
    makeCatDogFishQuestion,
    makeAntQuestion,
    makeFrogQuestion,
  ],
  makeQuestion: () => {
    const i = getRandomInt(QuestionBank.questions.length);
    return QuestionBank.questions[i]();
  },
};
